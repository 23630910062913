import type { ActionTree, GetterTree } from "vuex";
import type { IDataState } from "@/store/modules/data";
import { DataModules } from "@/store/modules/data/modules";
import type { ApiPathGetter } from "@/models/api";
import type { IState } from "@/store";
import type { IVaultAsset } from "@/models/vaultAssets";
import type { IClient } from "@/models/clients";
import _ from "@/boot/lodash";
import { BrandConfigKeys } from "@/data/constants";

const initialState = {} as IDataState;

const getters: GetterTree<IDataState, IState> = {
  apiPath:
    (s, g, rS, { isAdminContext, isMockClientContext }): ApiPathGetter =>
    clientId => {
      const admin = `api/admin/clients/${clientId}/vault`;
      const client = `api/clients/${clientId}/vault`;
      const contextual =
        isAdminContext && !isMockClientContext() ? admin : client;
      return { client, admin, contextual };
    },
  canShowNotesAndSecrets: (s, g, rs, rg) => {
    const config = rg["brand/config"];
    return _.get(
      config,
      BrandConfigKeys.CLIENT_NOTES_AND_SECRETS_ENABLED,
      false
    );
  }
};

const actions: ActionTree<IDataState, IState> = {
  list: ({ dispatch, getters }, payload) => {
    return dispatch(
      "data/list",
      {
        ...payload,
        path: getters.apiPath(payload?.id).contextual,
        storeModule: DataModules.CLIENTS_VAULT_ASSETS,
        params: {
          ...payload.params,
          with_staged_imports: 1
        }
      },
      { root: true }
    );
  },
  create: ({ dispatch, getters }, { id, data }) => {
    return dispatch(
      "data/create",
      {
        path: getters.apiPath(id).contextual,
        storeModule: DataModules.CLIENTS_VAULT_ASSETS,
        data
      },
      { root: true }
    );
  },
  update: ({ dispatch, getters }, { id, assetId, data }) => {
    return dispatch(
      "data/update",
      {
        path: `${getters.apiPath(id).contextual}/${assetId}`,
        storeModule: DataModules.CLIENTS_VAULT_ASSETS,
        data
      },
      { root: true }
    );
  },
  remove: (
    { dispatch, getters },
    { id, assetId }: { id: IClient["id"]; assetId: IVaultAsset["id"] }
  ) => {
    return dispatch(
      "data/remove",
      {
        path: `${getters.apiPath(id).contextual}/${assetId}`,
        storeModule: DataModules.CLIENTS_VAULT_ASSETS
      },
      { root: true }
    );
  },
  decrypt: ({ dispatch, getters }, { id, assetId }) => {
    return dispatch(
      "data/get",
      {
        path: `${getters.apiPath(id).contextual}/${assetId}/decrypt`,
        storeModule: DataModules.CLIENTS_VAULT_ASSETS,
        storeData: false
      },
      { root: true }
    );
  },
  openManageModal: ({ dispatch }, payload) => {
    return dispatch(
      "ui/open/modal",
      {
        config: {
          component: () =>
            import("@/components/app/global/vault/updateVaultAssetModal.vue"),
          ...payload,
          width: 640
        }
      },
      { root: true }
    );
  }
};

export default {
  namespaced: true,
  state: initialState,
  getters,
  actions
};
