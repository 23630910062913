<script lang="ts">
import { defineComponent } from "vue";
import { mapState } from "vuex";
import FilteringSortingTableStore from "@/mixins/tableWithFilteringAndSortingStore";
import Context from "@/mixins/context";
import CustomFieldsMixin from "@/mixins/customFieldsMixin";
import TagsMixin from "@/mixins/tagsMixin";
import MultilevelCategoriesFetcher from "@/components/app/admin/catalogue/products/multilevelCategories/multilevelCategoriesFetchMixin";
import { DataModules } from "@/store/modules/data/modules";
import { CustomFieldsMajorTypes } from "@/data/enums/customFields";
import { ApiOperators } from "@/data/table";
import {
  orderFilters,
  orderItemsFilters,
  orderClientsFilters
} from "@/data/filters/order";
import type { PropType, CreateElement } from "vue";
import type {
  IOrdersProvider,
  IOrdersProviderMethods
} from "@/models/providers/orders";
import type { ISorter, IFilter } from "@/models/tables";
import type { ITag } from "@/models/tags";
import type { IOrder } from "@/models/orders";
import type { IClient } from "@/models/clients";
import type { IState } from "@/store";

export default defineComponent({
  name: "OrdersProvider",
  mixins: [
    FilteringSortingTableStore,
    Context,
    TagsMixin,
    CustomFieldsMixin,
    MultilevelCategoriesFetcher
  ],
  provide() {
    return {
      ordersProvider: this.ordersProvider,
      ordersProviderMethods: this.ordersProviderMethods
    };
  },
  props: {
    clientId: { type: String as PropType<IClient["id"]>, default: null },
    id: { type: String, default: `OLP` },
    handleLoading: { type: Boolean, default: false },
    handleRequestError: { type: Boolean, default: true },
    availableFilters: { type: Array as PropType<IFilter[]>, default: () => [] },
    availableSorters: { type: Array as PropType<ISorter[]>, default: () => [] },
    manualFilters: {
      type: Object as PropType<Record<string, any>>,
      default: () => ({})
    }
  },
  data: () => ({
    dataModule: DataModules.ORDERS,
    debounceSearch: null as any,
    searchQuery: "",
    filters: [] as IFilter[],
    sorters: [] as ISorter[],
    isLoading: false,
    isReloading: false
  }),
  computed: {
    ...mapState({
      ordersWithParam(state: IState, getters): string {
        return getters[`data/${DataModules.ORDERS}/withParam`]();
      }
    }),
    queryFilter() {
      return this.searchQuery
        ? { [`filter[number|${ApiOperators.LIKE}]`]: `%${this.searchQuery}%` }
        : {};
    },
    guardFunctionalities() {
      return this.$store.getters[
        `data/${DataModules.ORDERS}/listFunctionalities`
      ];
    },
    contentIsAvailable() {
      return this.isClient || this.$userCan(...this.guardFunctionalities);
    },
    clientFilter() {
      if (this.clientId === null) return {};
      return { "filter[client_id]": this.clientId };
    },
    ordersProviderMethods(): IOrdersProviderMethods {
      return {
        getData: this.getData,
        doQuickSearch: this.doQuickSearch,
        onFiltersChange: this.onFiltersChange,
        onLimitChange: this.onLimitChange,
        onPageChange: this.onPageChange,
        onSort: this.onSort,
        reloadData: this.reloadData,
        applyTagFilter: this.applyTagFilter,
        debounceSearch: this.debounceSearch
      };
    }
  },
  created() {
    this.debounceSearch = this.$_.debounce(this.doQuickSearch, 250);
    this.filters = this.availableFilters;
    this.sorters = this.availableSorters;

    if (!this.contentIsAvailable) return;
    this.loadData();
  },
  methods: {
    async loadData() {
      try {
        this.isLoading = true;
        if (this.isAdmin) {
          await Promise.all([
            this.getCustomClientFields(this.isReloading),
            this.getCustomBasketFields(this.isReloading),
            this.getTags(this.isReloading),
            this.getRootCategories(this.isReloading)
          ]);
        }

        this.setFilters();
        await this.getData();
      } finally {
        this.isLoading = false;
      }
    },
    async reloadData() {
      try {
        this.isReloading = true;
        this.cancelRequests();
        return this.getData({ ignoreStored: true });
      } finally {
        this.isReloading = false;
      }
    },
    getStoreParams(params: any): Promise<any> {
      return this.$_.merge(
        {
          params: {
            with: this.ordersWithParam,
            ...this.queryFilter,
            ...this.manualFilters,
            ...this.clientFilter
          }
        },
        params // Important – must merge last
      );
    },
    async doQuickSearch(value = "") {
      if (value === this.searchQuery) return Promise.resolve();
      this.searchQuery = value;
      return this.getData({
        ignoreStored: true,
        page: 1
      });
    },
    applyTagFilter(tag: ITag) {
      let tagFilter = this.$_.find(this.activeFilters, ["key", "tags.id"]);
      if (!tagFilter) {
        tagFilter = this.createTagFilter(
          "tags.id",
          this.$t("_.client_tag"),
          "client"
        );
      }
      this.$set(tagFilter, "value", [tag.id]);
      this.onFiltersChange([tagFilter]);
    },
    setFilters() {
      this.filters = this.$_.concat(
        orderFilters(),
        this.isAdmin
          ? this.customFieldsFilters(
              CustomFieldsMajorTypes.BASKET,
              "",
              "order_custom_fields"
            )
          : [],
        this.getProductCategoryFilledFilters(orderItemsFilters(), false),
        this.isAdmin
          ? [
              this.createTagFilter(
                "products.tags.id",
                this.$t("_.order_item_tag").toString(),
                "order_item"
              )
            ]
          : [],
        this.isAdmin && !this.clientId ? orderClientsFilters() : [],
        this.isAdmin && !this.clientId
          ? [
              this.createTagFilter(
                "client.tags.id",
                this.$t("_.client_tag"),
                "client"
              )
            ]
          : [],
        this.isAdmin && !this.clientId
          ? this.customFieldsFilters(
              CustomFieldsMajorTypes.CLIENT,
              "client",
              "client_custom_fields"
            )
          : []
      );
    },
    ordersProvider(): IOrdersProvider {
      return {
        availableFilters: this.filters,
        availableSorters: this.sorters,
        activeFilters: this.activeFilters,
        constQueryFilters: this.constQueryFilters,
        activeSort: this.table.sort,
        enableFiltering: this.enableFiltering,
        enableSorting: this.enableSorting,
        hasOrders: !!this.table.data.length,
        isLoading: this.isLoading,
        isReloading: this.isReloading,
        limit: this.table.limit,
        page: this.table.page,
        orders: this.table.data as IOrder[],
        searchQuery: this.searchQuery,
        table: this.table,
        total: this.table.total,
        guardFunctionalities: this.guardFunctionalities,
        clientId: this.clientId
      };
    }
  },
  render(h: CreateElement) {
    // Handle loading
    if (this.handleLoading && this.loading)
      return h("is-loading", { props: { if: true } });
    // Handle request error
    if (this.handleRequestError && this.requestError)
      return h("request-error", {
        props: { isLoading: this.table.loading && this.requestError },
        on: { click: () => this.loadData() }
      });
    return (
      this.$scopedSlots?.default &&
      this.$scopedSlots.default({
        $ordersData: this.ordersProvider(),
        $ordersMethods: this.ordersProviderMethods
      })
    );
  }
});
</script>
