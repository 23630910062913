import _ from "@/boot/lodash";
import type { IFilter, IFiltersOptions } from "@/models/tables";
import { FilterOperators, FilterTypes } from "@/data/table";
import { FraudStatusMap } from "@/data/fraud";
import type { FraudStatus } from "@/data/enums";
import i18n from "@/i18n";
import type { IClient } from "@/models/clients";
import { CreatedAtFilter } from "@/data/filters/object";

export const IdParam = (id: IClient["id"]) => ({ "filter[id]": id });
export const NotGuestParam = () => ({ "filter[is_guest]": "0" });
export const UnverifiedParam = () => ({ "filter[verified]": "0" });

export const FromLast30DaysParam = () => ({
  "filter[created_at|after]": "-1_months"
});

export const FraudStatusParam = (status: FraudStatus) => ({
  "filter[fraud_status]": status
});

export const UsernameFilter = (override?: Partial<IFilter>) => ({
  key: "username",
  label: i18n.t("_.username"),
  type: FilterTypes.STRING,
  operator: FilterOperators.CONTAINS,
  group: "client",
  ...override
});

export const HasLoginFilter = (override?: Partial<IFilter>) => ({
  key: "has_login",
  label: i18n.t("_.has_login"),
  type: FilterTypes.SELECT_RADIO,
  operator: FilterOperators.EQUAL,
  value: "1",
  group: "client",
  ...override
});

export const FirstNameFilter = (override?: Partial<IFilter>) =>
  ({
    key: "firstname",
    label: i18n.t("_.firstname"),
    type: FilterTypes.STRING,
    operator: FilterOperators.CONTAINS,
    group: "client",
    ...override
  } as IFilter);

export const LastNameFilter = (override?: Partial<IFilter>) => ({
  key: "lastname",
  label: i18n.t("_.lastname"),
  type: FilterTypes.STRING,
  operator: FilterOperators.CONTAINS,
  group: "client",
  ...override
});

export const EmailFilter = (override?: Partial<IFilter>) => ({
  key: "default_email.email",
  label: i18n.t("_.email"),
  type: FilterTypes.STRING,
  operator: FilterOperators.CONTAINS,
  group: "client",
  ...override
});

export const IsGuestFilter = (override?: Partial<IFilter>) => ({
  key: "is_guest",
  label: i18n.t("_.guest_client"),
  type: FilterTypes.SELECT_RADIO,
  operator: FilterOperators.EQUAL,
  value: "true",
  group: "client",
  ...override
});

export const IdFilter = (override?: Partial<IFilter>) => ({
  key: "id",
  label: i18n.t("_.client_id"),
  type: FilterTypes.STRING,
  operator: FilterOperators.EQUAL,
  group: "client",
  ...override
});

export const ImportIdFilter = (override?: Partial<IFilter>) => ({
  key: "import_id",
  label: i18n.t("_.import_id"),
  type: FilterTypes.STRING,
  operator: FilterOperators.EQUAL,
  group: "client",
  ...override
});

export const VerifiedFilter = (override?: Partial<IFilter>) => ({
  key: "verified",
  label: i18n.t("_.verified"),
  type: FilterTypes.SELECT_RADIO,
  operator: FilterOperators.EQUAL,
  value: "1",
  group: "client",
  ...override
});

export const FraudStatusFilter = (override?: Partial<IFilter>) => ({
  key: "fraud_status",
  label: i18n.t("_.fraud_status"),
  type: FilterTypes.SELECT_CHECKBOX,
  operator: FilterOperators.EQUAL,
  options: _.map(_.orderBy(FraudStatusMap(), ["id", "asc"]), status => ({
    value: `${status.id}`,
    label: status.label
  })) as IFiltersOptions[],
  group: "client",
  ...override
});

export const AffiliateClientsParam = () => ({
  "filter[accounts.affiliate_account.id|neq]": "null",
  "filter[is_guest]": false
});

export const DefaultFilters = () => [
  UsernameFilter(),
  HasLoginFilter(),
  FirstNameFilter(),
  LastNameFilter(),
  IsGuestFilter(),
  CreatedAtFilter({ group: "client" }),
  IdFilter(),
  ImportIdFilter(),
  VerifiedFilter(),
  FraudStatusFilter()
];

export default DefaultFilters;
