<script lang="ts">
import { defineComponent } from "vue";
import { mapState } from "vuex";
import FilteringSortingTableStore from "@/mixins/tableWithFilteringAndSortingStore";
import Context from "@/mixins/context";
import TagsMixin from "@/mixins/tagsMixin";
import CustomFieldsMixin from "@/mixins/customFieldsMixin";
import MultilevelCategoriesFetcher from "@/components/app/admin/catalogue/products/multilevelCategories/multilevelCategoriesFetchMixin";
import { DataModules } from "@/store/modules/data/modules";
import { Sorters } from "@/data/table";
import {
  ApiOperators,
  FILTER_ARRAY_VALUES_SEPARATOR,
  FILTER_TYPES_WITH_ARRAY_VALUES,
  FilterTypes,
  ROUTER_OPERATOR_SEPARATOR
} from "@/data/table";
import {
  orderFilters,
  orderItemsFilters,
  orderClientsFilters
} from "@/data/filters/order";
import { CustomFieldsMajorTypes } from "@/data/enums/customFields";
import { ISegmentTypes } from "@/data/enums/segments";
import type { PropType, CreateElement } from "vue";
import type { ISegment } from "@/models/segments";
import type { IState } from "@/store";
import type {
  ISegmentResultsProviderData,
  ISegmentResultsProviderMethods
} from "@/models/providers/segments";
import { getOperatorFromValueAndApiOperator } from "@/helpers/table";
import type { IFilter, ISorter } from "@/models/tables";
import type { ITag } from "@/models/tags";
import _ from "@/boot/lodash";

export default defineComponent({
  name: "SegmentResultsProvider",
  mixins: [
    FilteringSortingTableStore,
    TagsMixin,
    CustomFieldsMixin,
    MultilevelCategoriesFetcher,
    Context
  ],
  provide() {
    return {
      segmentResultsProvider: this.segmentResultsProvider,
      segmentResultsProviderMethods: this.segmentResultsProviderMethods
    };
  },
  props: {
    segmentId: { type: String as PropType<ISegment["id"]>, required: true },
    segmentTypeHint: {
      type: String as PropType<ISegment["type"]>,
      default: null
    },
    id: { type: String, default: "SRP" },
    limit: { type: Number, default: 10 },
    handleLoading: { type: Boolean, default: true },
    handleRequestError: { type: Boolean, default: true },
    loaderAttrs: {
      type: Object as PropType<Record<string, any>>,
      default: () => ({})
    },
    manualFilters: {
      type: Object as PropType<Record<string, string | number | boolean>>,
      default: () => ({})
    },
    availableFilters: { type: Array as PropType<IFilter[]>, default: () => [] },
    availableSorters: { type: Array as PropType<ISorter[]>, default: () => [] }
  },
  data: () => ({
    dataModule: DataModules.SEGMENTS_RESULTS,
    debounceSearch: null as any,
    isProcessing: false,
    isReloading: false,
    searchQuery: ""
  }),
  computed: {
    ...mapState({
      segment(state: IState, getters): ISegment {
        return getters[`data/single`]({
          id: this.segmentId,
          storeModule: DataModules.SEGMENTS
        });
      },
      ordersWithParam(state: IState, getters): string {
        return getters[`data/${DataModules.ORDERS}/withParam`]();
      },
      clientsWithParam(state: IState, getters): string {
        return getters[`data/${DataModules.CLIENTS}/withParam`]();
      },
      cProdsWithParam(state: IState, getters): string {
        return getters[`data/${DataModules.CONTRACTS_PRODUCTS}/withParam`]();
      },
      isOrdersSegment(state: IState, getters): boolean {
        return getters[`data/${DataModules.SEGMENTS}/isOrdersSegment`](
          this.segmentType
        );
      },
      isClientsSegment(state: IState, getters): boolean {
        return getters[`data/${DataModules.SEGMENTS}/isClientsSegment`](
          this.segmentType
        );
      },
      isContractsProductsSegment(state: IState, getters): boolean {
        return getters[
          `data/${DataModules.SEGMENTS}/isContractsProductsSegment`
        ](this.segmentType);
      }
    }),
    newSegmentRouteName() {
      if (this.segmentType === ISegmentTypes.CONTRACTS_PRODUCTS_SUBSCRIPTION)
        return "adminSubscriptionSegmentList";
      else if (this.segmentType === ISegmentTypes.CONTRACTS_PRODUCTS_SALE)
        return "adminOneTimePurchasesSegmentList";
      else if (this.segmentType === ISegmentTypes.CLIENT)
        return "adminClientsSegments";
      else return null;
    },
    segmentType() {
      return this.segment?.type || this.segmentTypeHint;
    },
    queryFilter() {
      if (!this.searchQuery) return {};
      if (this.isContractsProductsSegment) {
        return {
          [`filter[product.name|${ApiOperators.LIKE}]`]: `%${this.searchQuery}%`
        };
      } else if (this.isClientsSegment) {
        return {
          [`query`]: `${this.searchQuery}`
        };
      }
      return {};
    },
    hasResults(): boolean {
      return !!this.table.data.length;
    },
    canShowMore(): boolean {
      return _.isNull(this.table.total)
        ? false
        : this.table.total > this.table.data.length;
    },
    withParam() {
      if (this.isContractsProductsSegment) return this.cProdsWithParam;
      else if (this.isClientsSegment) return this.clientsWithParam;
      else if (this.isOrdersSegment) return this.ordersWithParam;

      return [];
    },
    segmentResultsProviderMethods(): ISegmentResultsProviderMethods {
      return {
        doQuickSearch: this.doQuickSearch,
        onFiltersChange: this.onFiltersChange,
        onLimitChange: this.onLimitChange,
        onPageChange: this.onPageChange,
        onSort: this.onSort,
        reloadData: this.reloadData,
        getConstSegmentFilters: this.getConstSegmentFilters,
        getData: this.getData,
        applyTagFilter: this.applyTagFilter
      };
    }
  },
  async created() {
    this.debounceSearch = _.debounce(this.doQuickSearch, 250);
    this.defaults = {
      ...this.defaults,
      page: 1,
      limit: this.limit,
      sort: {
        field: "created_at",
        direction: Sorters.DESCENDING
      },
      filters: []
    };

    try {
      this.filters = this.availableFilters.filter(i =>
        i.if ? i.if(this) : true
      );
      this.staticTable.loading = true;
      await this.getSegment();
      await this.loadClientData();
      await this.loadOrdersData();
    } finally {
      this.staticTable.loading = false;
    }
    this.getData();
    this.$bus.$on("admin/client/tags/refresh", this.onTagsRefresh);
  },
  beforeDestroy() {
    this.$bus.$off("admin/client/tags/refresh");

    this.$store.dispatch("data/binSingle", {
      storeModule: DataModules.SEGMENTS,
      vm: this
    });
  },
  methods: {
    onTagsRefresh() {
      this.reloadData();
    },
    async loadClientData() {
      if (!this.isClientsSegment) return Promise.resolve();

      await this.getTags();
      this.filters.push(this.createTagFilter("tags.id", this.$t("_.tag"), ""));
    },
    async loadOrdersData() {
      if (!this.isOrdersSegment) return Promise.resolve();
      await Promise.all([
        this.getCustomClientFields(this.isReloading),
        this.getCustomBasketFields(this.isReloading),
        this.getTags(this.isReloading),
        this.getRootCategories(this.isReloading)
      ]);
      this.setOrdersFilters();
    },
    setOrdersFilters() {
      this.filters = _.concat(
        orderFilters(),
        this.isAdmin
          ? this.customFieldsFilters(
              CustomFieldsMajorTypes.BASKET,
              "",
              "order_custom_fields"
            )
          : [],
        this.getProductCategoryFilledFilters(orderItemsFilters(), false),
        this.isAdmin
          ? [
              this.createTagFilter(
                "products.tags.id",
                this.$t("_.order_item_tag").toString(),
                "order_item"
              )
            ]
          : [],
        this.isAdmin ? orderClientsFilters() : [],
        this.isAdmin
          ? [
              this.createTagFilter(
                "client.tags.id",
                this.$t("_.client_tag"),
                "client"
              )
            ]
          : [],
        this.isAdmin
          ? this.customFieldsFilters(
              CustomFieldsMajorTypes.CLIENT,
              "client",
              "client_custom_fields"
            )
          : []
      );
    },
    getSegment() {
      return this.$store.dispatch(`data/${DataModules.SEGMENTS}/get`, {
        id: this.segmentId,
        vm: this
      });
    },
    getStoreParams(params: any): Promise<any> {
      return _.merge(
        {
          id: this.segmentId,
          params: {
            with: this.withParam,
            ...this.queryFilter,
            ...this.manualFilters
          }
        },
        params // Important – must merge last
      );
    },
    async reloadData() {
      try {
        this.isReloading = true;
        this.cancelRequests();
        return this.getData({ ignoreStored: true });
      } finally {
        this.isReloading = false;
      }
    },
    doQuickSearch(value = "") {
      if (value === this.searchQuery) return Promise.resolve();
      this.searchQuery = value;
      return this.reloadData();
    },
    getConstSegmentFilters(availableFilters: IFilter[]): IFilter[] {
      const filters = _.get(this.segment, "params.filter", {});

      if (_.isEmpty(filters)) {
        return [];
      }

      return this.table.loading
        ? []
        : (_.map(filters, (value, filterKeyAndOperator) =>
            this.deSerialiseSegmentFilter(
              value,
              filterKeyAndOperator,
              availableFilters
            )
          ) as IFilter[]);
    },
    deSerialiseSegmentFilter(
      value,
      filterKeyAndOperator,
      filters: IFilter[]
    ): IFilter {
      const filterParams: string[] = filterKeyAndOperator.split(
        ROUTER_OPERATOR_SEPARATOR
      );
      const filter: IFilter = (_.find(filters, ["key", filterParams[0]]) ||
        {}) as IFilter;
      const type = _.get(filter, "type", FilterTypes.STRING);
      if (FILTER_TYPES_WITH_ARRAY_VALUES.includes(type)) {
        value = value.split(FILTER_ARRAY_VALUES_SEPARATOR);
      }

      return {
        ...filter,
        key: filterParams[0],
        value: value,
        operator: getOperatorFromValueAndApiOperator(
          value,
          filterParams[1] as ApiOperators
        )
      };
    },
    applyTagFilter(tag: ITag) {
      let tagFilter = _.find(this.activeFilters, ["key", "tags.id"]);
      if (!tagFilter) {
        tagFilter = this.createTagFilter(
          "tags.id",
          this.$t("_.client_tag"),
          "client"
        );
      }
      this.$set(tagFilter, "value", [tag.id]);
      this.onFiltersChange([tagFilter]);
    },
    segmentResultsProvider(): ISegmentResultsProviderData {
      return {
        availableFilters: this.filters,
        availableSorters: this.availableSorters,
        segment: this.segment,
        segmentType: this.segmentType,
        isContractsProductsSegment: this.isContractsProductsSegment,
        isClientsSegment: this.isClientsSegment,
        isOrdersSegment: this.isOrdersSegment,
        activeFilters: this.activeFilters,
        activeSort: this.table.sort,
        canShowMore: this.canShowMore,
        debounceSearch: this.debounceSearch,
        enableFiltering: this.enableFiltering,
        enableSorting: this.enableSorting,
        hasResults: this.hasResults,
        isLoading: this.table.loading,
        limit: this.table.limit,
        page: this.table.page,
        results: this.table.data as ISegmentResultsProviderData["results"],
        searchQuery: this.searchQuery,
        table: this.table,
        total: this.table.total,
        newSegmentRouteName: this.newSegmentRouteName,
        isReloading: this.isReloading
      };
    }
  },
  render(h: CreateElement) {
    // Handle loading
    if (this.handleLoading && this.loading)
      return h("is-loading", { props: { if: true } });
    // Handle request error
    if (this.handleRequestError && this.requestError)
      return h("request-error", {
        props: { isLoading: this.table.loading && this.requestError },
        on: { click: () => this.getData() }
      });
    return (
      this.$scopedSlots?.default &&
      this.$scopedSlots.default({
        $segmentResultsData: this.segmentResultsProvider(),
        $segmentResultsMethods: this.segmentResultsProviderMethods
      })
    );
  }
});
</script>
