import { RegexMatch } from "@/data/enums/router";
import { DocCategories } from "@/data/enums/docs";
import { ISegmentTypes } from "@/data/enums/segments";
import type { Route } from "vue-router";

import CProdsListing from "@/components/app/global/contractProducts/cProdsListing.vue";
import SegmentResultsListing from "@/components/app/admin/segments/segmentResultsListing.vue";
import { ContractStatusCodes } from "@/models/contracts";

import {
  statusParam,
  IsSubscriptionParam,
  AllStatusCodesFilter,
  isFromLast30DaysParam,
  IsCategoryParam,
  hasUnresolvedRequestsParam,
  CProdFiltersWithoutCategory
} from "@/data/filters/contractProducts";
import {
  DateCancelledSorter,
  DatePurchasedSorter,
  DefaultCProdSorters,
  StatusSorter
} from "@/data/sorters/contractProducts";

import { getDefaultCProdFilters } from "@/router/admin/contractProducts";
import { ListDisplayMode } from "@/data/constants";
import { UserMetaKeys } from "@/data/enums/users";

export default [
  {
    path: "subscriptions",
    name: "adminSubscriptions",
    redirect: { name: "adminSubscriptionsList" },
    components: {
      default: () =>
        import("@/views/admin/contractProducts/subscriptions/index.vue")
    },
    meta: {
      backTo: "_.subscriptions",
      categorySlug: DocCategories.USING_UPMIND,
      docsSlugs: ["product-management"]
    },
    children: [
      {
        path: "list",
        name: "adminSubscriptionsList",
        component: {
          name: "AdminSubscriptionsList",
          template: "<router-view />"
        },
        redirect: { name: "admin__allSubscriptions" },
        children: [
          {
            path: "all",
            name: "admin__allSubscriptions",
            component: CProdsListing,
            props: () => ({
              availableSorters: DefaultCProdSorters,
              availableFilters: () => [
                ...getDefaultCProdFilters(),
                AllStatusCodesFilter()
              ],
              routeFilters: () => ({
                ...IsSubscriptionParam()
              })
            })
          },
          {
            path: "active",
            name: "admin__activeSubscriptions",
            component: CProdsListing,
            props: () => ({
              availableSorters: DefaultCProdSorters,
              availableFilters: () => getDefaultCProdFilters(),
              routeFilters: () => ({
                ...IsSubscriptionParam(),
                ...statusParam([ContractStatusCodes.ACTIVE])
              })
            })
          },
          {
            path: "awaiting-activation",
            name: "admin__awaitingActivationSubscriptions",
            component: CProdsListing,
            props: () => ({
              availableSorters: DefaultCProdSorters,
              availableFilters: () => getDefaultCProdFilters(),
              routeFilters: () => ({
                ...IsSubscriptionParam(),
                ...statusParam([ContractStatusCodes.AWAITING_ACTIVATION])
              })
            })
          },
          {
            path: "pending-summary",
            name: "admin__pendingSummarySubscriptions",
            component: CProdsListing,
            props: () => ({
              availableSorters: DefaultCProdSorters,
              availableFilters: () => getDefaultCProdFilters(),
              routeFilters: () => ({
                ...IsSubscriptionParam(),
                ...statusParam([ContractStatusCodes.PENDING])
              })
            })
          },
          {
            path: "suspended",
            name: "admin__suspendedSubscriptions",
            component: CProdsListing,
            props: () => ({
              availableSorters: DefaultCProdSorters,
              availableFilters: () => getDefaultCProdFilters(),
              routeFilters: () => ({
                ...IsSubscriptionParam(),
                ...statusParam([ContractStatusCodes.SUSPENDED])
              })
            })
          },
          {
            path: "cancelled",
            name: "admin__cancelledSubscriptions",
            component: CProdsListing,
            props: () => ({
              availableSorters: () => [
                StatusSorter(),
                DateCancelledSorter(),
                DatePurchasedSorter()
              ],
              availableFilters: () => getDefaultCProdFilters(),
              routeFilters: () => ({
                ...IsSubscriptionParam(),
                ...statusParam([ContractStatusCodes.CANCELLED])
              })
            })
          },
          {
            path: "lapsed",
            name: "admin__lapsedSubscriptions",
            component: CProdsListing,
            props: () => ({
              availableSorters: DefaultCProdSorters,
              availableFilters: () => getDefaultCProdFilters(),
              routeFilters: () => ({
                ...IsSubscriptionParam(),
                ...statusParam([ContractStatusCodes.CLOSED])
              })
            })
          },
          {
            path: "last-30-days",
            name: "admin__fromLast30DaysSubscriptions",
            component: CProdsListing,
            props: () => ({
              availableSorters: DefaultCProdSorters,
              availableFilters: () => [
                ...getDefaultCProdFilters(),
                AllStatusCodesFilter()
              ],
              routeFilters: () => ({
                ...IsSubscriptionParam(),
                ...isFromLast30DaysParam()
              })
            })
          },
          {
            path: "has-provision-issues",
            name: "admin__hasProvisionIssuesSubscriptions",
            component: CProdsListing,
            props: {
              availableSorters: () => DefaultCProdSorters,
              availableFilters: () => [
                ...getDefaultCProdFilters(),
                AllStatusCodesFilter()
              ],
              routeFilters: () => ({
                ...IsSubscriptionParam(),
                ...hasUnresolvedRequestsParam()
              })
            }
          }
        ]
      },
      {
        path: `category/:categoryId(${RegexMatch.UUID})`,
        name: "admin__allSubscriptionsCategoryProducts",
        component: CProdsListing,
        props: {
          availableSorters: DefaultCProdSorters,
          availableFilters: () => [
            ...CProdFiltersWithoutCategory(),
            AllStatusCodesFilter()
          ],
          routeFilters: vm => ({
            ...IsSubscriptionParam(),
            ...IsCategoryParam(vm)
          })
        }
      },
      {
        path: `segment/:segmentId(${RegexMatch.UUID})`,
        name: "adminSubscriptionSegmentList",
        component: {
          name: "AdminSubscriptionsSegmentList",
          template: "<router-view />"
        },
        redirect: { name: "admin__subscriptionsSegment" },
        children: [
          {
            path: "",
            name: "admin__subscriptionsSegment",
            component: SegmentResultsListing,
            props: (route: Route) => ({
              defaultDisplayMode: ListDisplayMode.GRID,
              displayModeStoragePath: UserMetaKeys.UI_CPRODS_VIEW,
              segmentTypeHint: ISegmentTypes.CONTRACTS_PRODUCTS_SUBSCRIPTION,
              segmentId: route.params.segmentId,
              newSegmentRouteName: "adminSubscriptionSegmentList",
              availableSorters: DefaultCProdSorters,
              availableFilters: () => [
                ...getDefaultCProdFilters(),
                AllStatusCodesFilter()
              ]
            })
          }
        ]
      }
    ]
  }
];
