import type { IDataState } from "@/store/modules/data";
import { DataModules } from "@/store/modules/data/modules";
import type { ActionTree, GetterTree } from "vuex";
import type { IState } from "@/store";
import _ from "@/boot/lodash";
import type { ISegment } from "@/models/segments";
import { ISegmentTypes } from "@/data/enums/segments";

import { DEFAULT_SINGLE_SCOPE } from "@/store/modules/data";
import type { ApiPathGetter } from "@/models/api";

const initialState = {} as IDataState;

const getters: GetterTree<IDataState, IState> = {
  apiPath: (): ApiPathGetter => () => {
    const admin = `api/admin/segments`;
    return { admin };
  },
  scope: () => id => {
    return `$segment_${id}`;
  },
  isOrdersSegment: () => (type: ISegment["type"]) => {
    return type === ISegmentTypes.ORDER;
  },
  isClientsSegment: () => (type: ISegment["type"]) => {
    return type === ISegmentTypes.CLIENT;
  },
  isContractsProductsSegment: () => (type: ISegment["type"]) => {
    return (
      type === ISegmentTypes.CONTRACTS_PRODUCTS ||
      type === ISegmentTypes.CONTRACTS_PRODUCTS_SUBSCRIPTION ||
      type === ISegmentTypes.CONTRACTS_PRODUCTS_SALE
    );
  }
};

const actions: ActionTree<IDataState, IState> = {
  list: ({ dispatch, getters }, payload) => {
    return dispatch(
      "data/list",
      {
        ...payload,
        path: getters.apiPath().admin,
        storeModule: DataModules.SEGMENTS
      },
      { root: true }
    );
  },
  get: ({ dispatch, getters }, payload) => {
    return dispatch(
      "data/get",
      {
        ...payload,
        path: `${getters.apiPath().admin}/${payload.id}`,
        storeModule: DataModules.SEGMENTS
      },
      { root: true }
    );
  },
  create: async ({ dispatch, commit, getters }, payload) => {
    const segment = await dispatch(
      "data/create",
      {
        ...payload,
        path: getters.apiPath().admin,
        storeModule: DataModules.SEGMENTS
      },
      { root: true }
    );

    await commit(
      "data/addSingleItemList",
      {
        scope: payload.scope,
        path: `data.${segment.id}`,
        storeModule: DataModules.SEGMENTS,
        data: segment
      },
      { root: true }
    );

    return segment;
  },
  update: async ({ dispatch, commit, getters }, payload) => {
    if (!_.has(payload, "id")) {
      throw new Error("Segment id not provided");
    }

    const segment = await dispatch(
      "data/update",
      {
        ...payload,
        path: `${getters.apiPath().admin}/${payload.id}`,
        storeModule: DataModules.SEGMENTS
      },
      { root: true }
    );
    await commit(
      `data/updateSingleItemList`,
      {
        scope: payload.scope,
        path: `data.${segment.id}`,
        storeModule: DataModules.SEGMENTS,
        data: segment
      },
      { root: true }
    );
    await commit(
      `data/replaceData`,
      {
        scope: DEFAULT_SINGLE_SCOPE,
        data: segment,
        storeModule: DataModules.SEGMENTS
      },
      { root: true }
    );
    return segment;
  },
  remove: async ({ dispatch, commit, getters }, payload) => {
    if (!_.has(payload, "id")) {
      throw new Error("id not provided to delete");
    }
    const results = await dispatch(
      "data/remove",
      {
        ...payload,
        path: `${getters.apiPath().admin}/${payload.id}`,
        storeModule: DataModules.SEGMENTS
      },
      { root: true }
    );

    await commit(
      "data/binSingleItemList",
      {
        scope: payload.scope,
        path: `data.${payload.id}`,
        storeModule: DataModules.SEGMENTS
      },
      { root: true }
    );

    return results;
  },
  openManageSegmentModal: ({ dispatch }, payload) => {
    return dispatch(
      "ui/open/slideModal",
      {
        config: {
          component: () =>
            import("@/components/app/admin/segments/addEditSegmentModal.vue"),
          ...payload
        }
      },
      { root: true }
    );
  }
};

export default {
  namespaced: true,
  state: initialState,
  getters,
  actions,
  modules: {
    results: require("./results").default
  }
};
