import _ from "@/boot/lodash";
import store from "@/store";
import { BrandConfigKeys } from "@/data/constants";
import { ClientRoutes, RegexMatch } from "@/data/enums/router";
import type { Component, CreateElement } from "vue";
import { EmailTypes } from "@/data/enums/emails";
import type { Location, Route } from "vue-router";
import {
  IsSentParam,
  IsBouncedParam,
  IsFailedParam
} from "@/data/filters/emails";

// Components
import ClientAccountMenu from "@/components/app/client/accountMenu.vue";
import ClientDefaultLayout from "@/skeleton/client/loggedIn/defaultLayout.vue";
import EmailHistoryTable from "@/components/app/global/emailHistory/emailHistoryTable.vue";
import EmailsHistoryProvider from "@/components/app/global/emailHistory/emailsHistoryProvider.vue";

const renderEmailHistory = ({ manualFilters = {} }) => ({
  render(h: CreateElement) {
    return h(EmailsHistoryProvider, {
      props: {
        emailType: EmailTypes.MYSELF,
        manualFilters: manualFilters || {}
      },
      scopedSlots: {
        default: () =>
          h(EmailHistoryTable, {
            props: {
              rowProps: email => ({
                to: {
                  name: ClientRoutes.EMAIL_PREVIEW,
                  params: { emailId: email.id }
                }
              })
            }
          })
      }
    });
  }
});

export default [
  {
    path: "account",
    components: {
      default: () => import("@/views/client/index.vue"),
      layout: {
        render: h =>
          h(ClientDefaultLayout, {
            scopedSlots: {
              menu: () =>
                h(ClientAccountMenu, {
                  props: {
                    routes: require("./menu").clientAccountMenu
                  }
                })
            }
          })
      } as Component
    },
    beforeEnter: async (to: any, from: any, next: any) => {
      try {
        await store.dispatch("brand/getConfig", {
          keys: [
            BrandConfigKeys.UPMIND_AFFILIATES_CUSTOMER_CONTROLS_ENABLED,
            BrandConfigKeys.UPMIND_AFFILIATES_ENABLED
          ]
        });
      } catch (error) {
        // Mask error for better UX
      } finally {
        next();
      }
    },
    meta: {},
    children: [
      {
        path: "",
        name: ClientRoutes.ACCOUNT,
        redirect: { name: ClientRoutes.ACCOUNT_PROFILE }
      },
      {
        path: "profile",
        name: ClientRoutes.ACCOUNT_PROFILE,
        component: () => import("@/views/client/account/profile/index.vue"),
        meta: {
          title: "_.profile",
          breadcrumb: {
            label: "_.account"
          }
        }
      },
      {
        path: "child-accounts",
        name: ClientRoutes.CHILD_ACCOUNTS,
        component: () =>
          import("@/views/client/account/childAccounts/index.vue"),
        meta: {
          title: "_.child_accounts",
          breadcrumb: {
            label: "_.account"
          }
        }
      },
      {
        path: "email-history",
        name: ClientRoutes.EMAIL_HISTORY,
        redirect: { name: ClientRoutes.EMAIL_HISTORY_ALL },
        component: () =>
          import("@/views/client/account/emailHistory/index.vue"),
        meta: {
          breadcrumb: {
            label: "_.email_history"
          }
        },
        children: [
          {
            path: "failed",
            name: ClientRoutes.EMAIL_HISTORY_FAILED,
            component: renderEmailHistory({
              manualFilters: {
                ...IsFailedParam()
              }
            })
          },
          {
            path: "bounced",
            name: ClientRoutes.EMAIL_HISTORY_BOUNCED,
            component: renderEmailHistory({
              manualFilters: {
                ...IsBouncedParam()
              }
            })
          },
          {
            path: "sent",
            name: ClientRoutes.EMAIL_HISTORY_SENT,
            component: renderEmailHistory({
              manualFilters: {
                ...IsSentParam()
              }
            })
          },
          {
            path: "all",
            name: ClientRoutes.EMAIL_HISTORY_ALL,
            component: renderEmailHistory({}),
            children: [
              {
                path: `:emailId(${RegexMatch.UUID})`,
                name: ClientRoutes.EMAIL_PREVIEW,
                beforeEnter: async (
                  to: Route,
                  from: Route,
                  next: (options: boolean | Location | Location["path"]) => void
                ) => {
                  const { emailId } = to.params;
                  if (emailId) {
                    store.dispatch("ui/open/modal", {
                      config: {
                        component: () =>
                          import(
                            "@/components/app/global/emailHistory/viewEmailModal.vue"
                          ),
                        canCancel: ["button", "escape", "outside"],
                        props: {
                          viewRoute: params => ({
                            name: ClientRoutes.EMAIL_PREVIEW,
                            params
                          }),
                          emailId
                        }
                      }
                    });
                  }
                  store.dispatch("ui/endRouting");
                  return next(!from.matched.length); // Important to maintain pagination/filters etc on parent list view
                }
              }
            ]
          }
        ]
      },
      {
        path: "security",
        name: ClientRoutes.ACCOUNT_SECURITY,
        component: () => import("@/views/client/account/security/index.vue"),
        meta: {
          title: "_.security",
          breadcrumb: {
            label: "_.security"
          }
        }
      },
      {
        path: "affiliate",
        name: ClientRoutes.AFFILIATE,
        redirect: { name: ClientRoutes.AFFILIATE_OVERVIEW },
        component: () => import("@/views/client/account/affiliate/index.vue"),
        meta: {
          title: "_.affiliate_program",
          breadcrumb: {
            label: "_.affiliate_program"
          }
        },
        props: () => ({
          accountId: _.get(store.state, "auth.client.accountId")
        }),
        children: [
          {
            path: "overview",
            name: ClientRoutes.AFFILIATE_OVERVIEW,
            component: () =>
              import("@/views/client/account/affiliate/overview/index.vue"),
            meta: {
              backTo: "_.overview"
            }
          },
          {
            path: "commissions",
            name: ClientRoutes.AFFILIATE_COMMISSIONS,
            component: () =>
              import("@/views/client/account/affiliate/commissions/index.vue"),
            meta: {
              backTo: "_.commissions"
            }
          },
          {
            path: "payouts",
            name: ClientRoutes.AFFILIATE_PAYOUTS,
            component: () =>
              import("@/views/client/account/affiliate/payouts/index.vue"),
            meta: {
              backTo: "_.payouts"
            }
          }
        ]
      },
      {
        path: "notes",
        name: ClientRoutes.ACCOUNT_NOTES,
        component: () => import("@/views/client/account/vault/index.vue"),
        meta: {
          title: "_.notes_and_secrets",
          breadcrumb: {
            label: "_.notes_and_secrets"
          }
        },
        props: () => ({
          accountId: _.get(store.state, "auth.client.accountId")
        }),
        beforeEnter: async (to: any, from: any, next: any) => {
          const config = store.getters["brand/config"];
          next(
            _.get(config, BrandConfigKeys.CLIENT_NOTES_AND_SECRETS_ENABLED)
              ? {}
              : { name: ClientRoutes.ACCOUNT }
          );
        }
      },
      {
        path: "*",
        component: () => import("@/views/error/index.vue"),
        meta: { error: 404 }
      }
    ]
  }
];
