import i18n from "@/i18n";
import type { IMenuRoute } from "@/models/routes";

export const AccountRoutes: () => IMenuRoute[] = () => [
  {
    label: i18n.t("_.profile"),
    to: {
      name: "adminAccountProfile"
    }
  },
  {
    label: i18n.t("_.security"),
    to: {
      name: "adminAccountSecurity"
    }
  },
  {
    label: i18n.t("_.email_history"),
    to: {
      name: "adminAccountEmailHistory"
    }
  },
  {
    label: i18n.t("_.activity_log"),
    to: {
      name: "adminAccountActivityLog"
    }
  }
];
