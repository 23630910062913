import store from "@/store";
import { DataModules } from "@/store/modules/data/modules";
import type { Location, Route } from "vue-router";
import { RegexMatch } from "@/data/enums/router";
import { DocCategories } from "@/data/enums/docs";
import type { IModalComponent } from "@/models/modal";
import { FraudStatus } from "@/data/enums";
import { ISegmentTypes } from "@/data/enums/segments";

import {
  OrderStatusParam,
  FromLast7DaysParam,
  FromLast24HoursParam,
  FromLast30DaysParam,
  FraudStatusParam
} from "@/data/filters/order";
import { DefaultOrderSorters } from "@/data/sorters/order";
import OrdersListing from "@/components/app/global/orders/ordersListing.vue";
import { InvoiceStatus } from "@/data/enums/invoice";
import SegmentResultsListing from "@/components/app/admin/segments/segmentResultsListing.vue";
import { ListDisplayMode } from "@/data/constants";
import { DateCancelledSorter } from "@/data/sorters/invoices";
import _ from "@/boot/lodash";

export default [
  {
    path: "orders",
    components: {
      default: {
        name: "AdminOrders",
        template: `<router-view />`
      }
    },
    meta: {
      breadcrumb: {
        label: "_.orders"
      }
    },
    children: [
      {
        path: "",
        name: "adminOrders",
        component: () => import("@/views/admin/orders/index.vue"),
        props: {
          routes: require("./menu").ordersTabigation
        },
        meta: {
          auth: ["staff_list_invoices", "list_contracts"],
          title: "_.orders"
        },
        redirect: { name: "adminOrdersOverview" },
        children: [
          {
            path: "overview",
            name: "adminOrdersOverview",
            components: {
              default: () => import("@/views/admin/orders/overview/index.vue")
            },
            meta: {
              auth: ["list_contracts"],
              backTo: "_.orders",
              categorySlug: DocCategories.SETUP_CONFIGURATION,
              docsSlugs: ["orders-services"]
            },
            children: [
              {
                path: `:orderId(${RegexMatch.UUID})`,
                name: "adminOrder",
                beforeEnter: async (
                  to: Route,
                  from: Route,
                  next: (
                    options: boolean | Location | Location["path"]
                  ) => Promise<void>
                ) => {
                  const { orderId } = to.params;
                  // Abort routing if no `orderId`
                  if (!orderId) return next(false);
                  // Check for authenticated 'from' route
                  const isAuthenticatedFromRoute = !store.getters[
                    "auth/admin/isLoggedOutPath"
                  ](from.path);
                  // If routed from an authenticated route...
                  if (from.matched.length && isAuthenticatedFromRoute) {
                    // Block routing
                    next(false);
                    // End routing progress
                    store.dispatch("ui/endRouting");
                  } else {
                    // Allow routing to parent location
                    await next({
                      path: to.fullPath.replace(orderId, ""),
                      replace: true
                    });
                  }
                  // Open preview modal
                  const modal: IModalComponent = await store.dispatch(
                    `data/${DataModules.ORDERS}/openPreviewModal`,
                    {
                      props: {
                        orderId,
                        autoCloseOnRouteChange: false
                      },
                      events: {
                        ["route-change"]: ({ from }: { from: Route }) => {
                          const isAuthenticatedFromRoute = !store.getters[
                            "auth/admin/isLoggedOutPath"
                          ](from.path);
                          if (isAuthenticatedFromRoute) modal.close();
                        }
                      }
                    }
                  );
                }
              }
            ]
          },
          {
            path: "list",
            components: {
              default: () => import("@/views/admin/orders/list/index.vue")
            },
            meta: {
              auth: ["list_contracts"]
            },
            props: {
              default: {
                routes: require("./menu").ordersQuickFilters
              }
            },
            children: [
              {
                path: "",
                name: "adminOrdersList",
                redirect: { name: "adminOrders_all" },
                component: {
                  name: "AdminOrdersView",
                  template: "<router-view/>"
                },
                meta: {
                  backTo: "_.orders",
                  categorySlug: DocCategories.SETUP_CONFIGURATION,
                  docsSlugs: ["orders-services"]
                },
                children: [
                  {
                    path: "all",
                    name: "adminOrders_all",
                    component: OrdersListing,
                    props: () => ({
                      availableSorters: DefaultOrderSorters
                    }),
                    meta: {
                      categorySlug: DocCategories.SETUP_CONFIGURATION,
                      docsSlugs: ["orders-services"]
                    }
                  },
                  {
                    path: "paid",
                    name: "adminOrders_paid",
                    component: OrdersListing,
                    props: () => ({
                      availableSorters: DefaultOrderSorters,
                      manualFilters: () => OrderStatusParam(InvoiceStatus.PAID)
                    }),
                    meta: {
                      categorySlug: DocCategories.SETUP_CONFIGURATION,
                      docsSlugs: ["orders-services"]
                    }
                  },
                  {
                    path: "unpaid",
                    name: "adminOrders_unpaid",
                    component: OrdersListing,
                    props: () => ({
                      availableSorters: DefaultOrderSorters,
                      manualFilters: () => ({
                        ...OrderStatusParam(
                          InvoiceStatus.UNPAID,
                          InvoiceStatus.ADJUSTED,
                          InvoiceStatus.OVERDUE
                        )
                      })
                    }),
                    meta: {
                      categorySlug: DocCategories.SETUP_CONFIGURATION,
                      docsSlugs: ["orders-services"]
                    }
                  },
                  {
                    path: "cancelled",
                    name: "adminOrders_cancelled",
                    component: OrdersListing,
                    props: () => ({
                      availableSorters: () =>
                        _.orderBy(
                          [...DefaultOrderSorters(), DateCancelledSorter()],
                          ["label"],
                          ["asc"]
                        ),
                      manualFilters: () => ({
                        ...OrderStatusParam(InvoiceStatus.CANCELLED)
                      })
                    }),
                    meta: {
                      categorySlug: DocCategories.SETUP_CONFIGURATION,
                      docsSlugs: ["orders-services"]
                    }
                  },
                  {
                    path: "last_24_hours",
                    name: "adminOrders_last_24_hours",
                    component: OrdersListing,
                    props: () => ({
                      availableSorters: DefaultOrderSorters,
                      manualFilters: () => FromLast24HoursParam()
                    }),
                    meta: {
                      categorySlug: DocCategories.SETUP_CONFIGURATION,
                      docsSlugs: ["orders-services"]
                    }
                  },
                  {
                    path: "last_7_days",
                    name: "adminOrders_last_7_days",
                    component: OrdersListing,
                    props: () => ({
                      availableSorters: DefaultOrderSorters,
                      manualFilters: () => FromLast7DaysParam()
                    }),
                    meta: {
                      categorySlug: DocCategories.SETUP_CONFIGURATION,
                      docsSlugs: ["orders-services"]
                    }
                  },
                  {
                    path: "last_30_days",
                    name: "adminOrders_last_30_days",
                    component: OrdersListing,
                    props: () => ({
                      availableSorters: DefaultOrderSorters,
                      manualFilters: () => FromLast30DaysParam()
                    }),
                    meta: {
                      categorySlug: DocCategories.SETUP_CONFIGURATION,
                      docsSlugs: ["orders-services"]
                    }
                  },
                  {
                    path: "preview",
                    name: "adminOrders_preview",
                    component: OrdersListing,
                    props: () => ({
                      availableSorters: DefaultOrderSorters,
                      manualFilters: () => FraudStatusParam(FraudStatus.REVIEW)
                    }),
                    meta: {
                      categorySlug: DocCategories.SETUP_CONFIGURATION,
                      docsSlugs: ["orders-services"]
                    }
                  },
                  {
                    path: "blocked",
                    name: "adminOrders_blocked",
                    component: OrdersListing,
                    props: () => ({
                      availableSorters: DefaultOrderSorters,
                      manualFilters: () => FraudStatusParam(FraudStatus.FRAUD)
                    }),
                    meta: {
                      categorySlug: DocCategories.SETUP_CONFIGURATION,
                      docsSlugs: ["orders-services"]
                    }
                  }
                ]
              },
              {
                path: `segments/:segmentId(${RegexMatch.UUID})`,
                name: "adminOrdersSegments",
                component: {
                  name: "AdminOrdersSegmentList",
                  template: `<router-view  :key="$route.params.segmentId"/>`
                },
                redirect: { name: "adminOrders_segment" },
                meta: {
                  auth: ["list_segments"],
                  requiresBrand: true,
                  backTo: "_.order_segments"
                },
                children: [
                  {
                    path: "",
                    name: "adminOrders_segment",
                    component: SegmentResultsListing,
                    props: (route: Route) => ({
                      segmentId: route.params.segmentId,
                      segmentTypeHint: ISegmentTypes.ORDER,
                      newSegmentRouteName: "adminOrdersSegments",
                      defaultDisplayMode: ListDisplayMode.TABLE,
                      displayModes: [ListDisplayMode.TABLE],
                      availableSorters: DefaultOrderSorters
                    })
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  }
];
