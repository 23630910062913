import type { ActionTree } from "vuex";
import { AppHookCodes } from "@/data/enums/hooks";
import type { IBasketProduct } from "@/models/baskets";
import type { IClient } from "@/models/clients";
import type { IState } from "@/store";
import type { IUser } from "@/models/users";
import { UPM_DATA_LAYER } from "@/boot/gtm";

const initialState = () => ({});

const actions: ActionTree<{}, IState> = {
  [AppHookCodes.ADD_TO_BASKET_SUCCESS]: async (
    context,
    basketProduct: IBasketProduct
  ) => {
    // Push 'add_to_cart' event
    UPM_DATA_LAYER.push({ ecommerce: null });
    UPM_DATA_LAYER.push({
      event: "add_to_cart",
      ecommerce: {
        currency: basketProduct.base_currency_code,
        value: basketProduct.configuration_net_amount_discounted_converted,
        items: [
          {
            item_id: basketProduct.product.id,
            item_name: basketProduct.product.name, // For reporting purposes we intentionally pass untranslated product name
            item_category: basketProduct.product.category.name, // For reporting purposes we intentionally pass untranslated category name
            quantity: basketProduct.quantity,
            discount: basketProduct.configuration_net_amount_discount_converted,
            price: basketProduct.configuration_net_amount_converted,
            upm_report_code_1: basketProduct.product.report_code_1, // item-scoped custom Upmind parameter
            upm_report_code_2: basketProduct.product.report_code_2 // item-scoped custom Upmind parameter
          }
        ]
      }
    });
  },
  [AppHookCodes.ADMIN_USER_LOGGED_IN]: async (
    { dispatch, rootState },
    user: IUser
  ) => {
    await dispatch("ui/userflow/tryInit", user, { root: true });
    /** Here we check if the current brand has a demo data import_id, and
     * initiate a check to see if the import is complete or in progress */
    if (rootState.brand?.demo_data_import_id)
      dispatch("demoData/checkImportStatus", null, { root: true });
  },
  [AppHookCodes.CLIENT_REGISTERED]: (context, clientId: IClient["id"]) => {
    // Push 'sign_up' event + set `upmind.user_id`
    UPM_DATA_LAYER.push({
      event: "sign_up",
      upmind: {
        user_id: clientId
      }
    });
  }
};

export default {
  namespaced: true,
  state: initialState(),
  actions
};
