<script lang="ts">
import { defineComponent } from "vue";
import Context from "@/mixins/context";
import ExternalAuthMixin from "@/components/app/global/payments/externalAuthMixin.vue";
import { DataModules } from "@/store/modules/data/modules";
import InvoiceAutoPayModal from "@/components/app/global/payments/invoiceAutoPayModal.vue";
import TopUpWalletAutoPayModal from "@/components/app/global/wallets/topUpWalletAutoPayModal.vue";
import type { PropType } from "vue";
import type { IInvoice } from "@/models/invoices";
import type { IClient } from "@/models/clients";

export default defineComponent({
  name: "OpenAutoPayModal",
  mixins: [ExternalAuthMixin, Context],
  props: {
    invoiceId: { type: String as PropType<IInvoice["id"]>, default: "" },
    clientId: { type: String as PropType<IClient["id"]>, default: "" }
  },
  computed: {
    computedClientId() {
      return this.clientId || this.autoPayQueryParam?.clientId;
    },
    computedInvoiceId() {
      return this.invoiceId || this.autoPayQueryParam?.invoiceId;
    },
    isInvoicePayment() {
      return !!this.computedInvoiceId;
    },
    isWalletTopUp() {
      return !!(
        !this.computedInvoiceId &&
        this.computedClientId &&
        this.autoPayQueryParam?.accountId &&
        this.autoPayQueryParam?.currencyId
      );
    }
  },
  mounted() {
    this.checkExternalAuthQueryParams();
    if (!this.computedClientId && !this.computedInvoiceId) return this.close();

    if (
      !this.paymentDetailIdFromExternalAuth ||
      this.$_.isEmpty(this.autoPayQueryParam) ||
      !this.isSuccess
    )
      return this.close();

    // Top up wallet
    if (this.isInvoicePayment) this.openPayInvoiceModal();
    else if (this.isWalletTopUp) this.openTopUpModal();
    else this.close();
  },
  methods: {
    async openPayInvoiceModal() {
      const invoice = await this.$store.dispatch(
        `data/${DataModules.INVOICES}/getWithParams`,
        {
          id: this.computedInvoiceId,
          storeData: false,
          isClient: this.isClient
        }
      );

      this.$buefy.modal.open({
        parent: window.$rootVue,
        component: InvoiceAutoPayModal,
        props: {
          amount: this.autoPayQueryParam?.amount,
          invoice: invoice,
          preselectedStoredPaymentMethodId:
            this.paymentDetailIdFromExternalAuth,
          walletAmount: this.autoPayQueryParam?.walletAmount
        },
        events: {
          success: ({ invoiceId }) => {
            this.$bus.$emit("autopay-invoice-success", { invoiceId });
          },
          error: error => {
            this.$bus.$emit("autopay-invoice-error", {
              error
            });
          },
          close: () => {
            this.close();
          }
        },
        width: 480,
        trapFocus: true
      });
    },
    async openTopUpModal() {
      this.$buefy.modal.open({
        parent: this,
        component: TopUpWalletAutoPayModal,
        props: {
          amount: this.autoPayQueryParam?.amount,
          clientId: this.computedClientId,
          currencyId: this.autoPayQueryParam?.currencyId,
          accountId: this.autoPayQueryParam?.accountId,
          preselectedStoredPaymentMethodId: this.paymentDetailIdFromExternalAuth
        },
        events: {
          success: () => {
            this.$bus.$emit("autopay-wallet-success", {
              clientId: this.computedClientId
            });
          },
          error: error => {
            this.$bus.$emit("autopay-wallet-error", {
              clientId: this.computedClientId,
              error
            });
          },
          close: () => {
            this.close();
          }
        },
        width: 420,
        trapFocus: true
      });
    },
    close() {
      this.$emit("close");
    },
    render() {
      return this.$scopedSlots?.default && this.$scopedSlots.default({});
    }
  }
});
</script>
