<script lang="ts">
import { defineComponent } from "vue";
import { mapState } from "vuex";
import _ from "@/boot/lodash";
import FilteringSortingTableStore from "@/mixins/tableWithFilteringAndSortingStore";
import Context from "@/mixins/context";
import { DataModules } from "@/store/modules/data/modules";
import { InvoiceCategoryCode } from "@/models/invoices";
import { InvoiceNumberSorter } from "@/data/sorters/invoices";
import { ApiOperators } from "@/data/table";
import type { PropType, CreateElement } from "vue";
import type {
  IInvoicesProvider,
  IInvoicesProviderMethods
} from "@/models/providers/invoice";
import type { ISorter, IFilter } from "@/models/tables";
import type { IClient } from "@/models/clients";
import type { IState } from "@/store";
import type { IContract, IContractProduct } from "@/models/contracts";
import type { InvoiceStatus } from "@/data/enums/invoice";
import type { IInvoice } from "@/models/invoices";

export default defineComponent({
  name: "InvoicesProvider",
  mixins: [FilteringSortingTableStore, Context],
  provide() {
    return {
      invoicesProvider: this.invoicesProvider,
      invoicesProviderMethods: this.invoicesProviderMethods
    };
  },
  props: {
    clientId: { type: String as PropType<IClient["id"]>, default: null },
    withParams: { type: String, default: "" },
    id: { type: String, default: `ILP` },
    contractId: { type: String as PropType<IContract["id"]>, default: null },
    statuses: { type: Array as PropType<InvoiceStatus[]>, default: () => [] },
    productId: {
      type: String as PropType<IContractProduct["id"]>,
      default: undefined
    },
    handleLoading: { type: Boolean, default: false },
    handleRequestError: { type: Boolean, default: true },
    availableFilters: { type: Array as PropType<IFilter[]>, default: () => [] },
    availableSorters: { type: Array as PropType<ISorter[]>, default: () => [] },
    manualFilters: {
      type: Object as PropType<Record<string, any>>,
      default: () => ({})
    }
  },
  data: () => ({
    dataModule: DataModules.INVOICES,
    debounceSearch: null as any,
    searchQuery: "",
    filters: [] as IFilter[],
    sorters: [] as ISorter[],
    isLoading: false,
    isReloading: false
  }),
  computed: {
    ...mapState({
      invoicesWithParam(state: IState, getters): string {
        return getters[`data/${DataModules.INVOICES}/withParam`]({
          clientId: this.clientId,
          withParams: this.withParams
        });
      }
    }),
    queryFilter() {
      return this.searchQuery
        ? { [`filter[number|${ApiOperators.LIKE}]`]: `%${this.searchQuery}%` }
        : {};
    },
    guardFunctionalities() {
      return this.$store.getters[
        `data/${DataModules.INVOICES}/listFunctionalities`
      ];
    },
    contentIsAvailable() {
      return this.isClient || this.$userCan(...this.guardFunctionalities);
    },
    clientFilter() {
      return this.clientId ? { client_id: this.clientId } : {};
    },
    contractFilter() {
      return this.contractId ? { "filter[contracts.id]": this.contractId } : {};
    },
    contractProductFilter() {
      return this.productId
        ? { "filter[products.contracts_product_id]": this.productId }
        : {};
    },
    stagedImportFilter() {
      return this.clientId || this.contractId || this.productId
        ? { with_staged_imports: 1 }
        : {};
    },
    statusFilter() {
      return this.statuses?.length
        ? { "filter[status.code]": this.statuses.join(",") }
        : {};
    },
    categoryFilter() {
      return {
        "filter[category.slug]": [
          InvoiceCategoryCode.NEW_CONTRACT,
          InvoiceCategoryCode.ADDITIONAL_SERVICE,
          InvoiceCategoryCode.ONE_TIME_SERVICE,
          InvoiceCategoryCode.MIGRATION,
          InvoiceCategoryCode.RECURRENT
        ].join(",")
      };
    },
    invoicesProviderMethods(): IInvoicesProviderMethods {
      return {
        debounceSearch: this.debounceSearch,
        doQuickSearch: this.doQuickSearch,
        getData: this.getData,
        onFiltersChange: this.onFiltersChange,
        onLimitChange: this.onLimitChange,
        onPageChange: this.onPageChange,
        onSort: this.onSort,
        refreshData: this.refreshData,
        reloadData: this.reloadData
      };
    }
  },
  created() {
    this.defaults = {
      ...this.defaults,
      page: 1,
      limit: this.limit,
      sort: {
        ...this.defaults.sort,
        field: InvoiceNumberSorter().field
      },
      filters: []
    };

    this.debounceSearch = _.debounce(this.doQuickSearch, 250);
    this.filters = this.availableFilters;
    this.sorters = this.availableSorters;

    if (!this.contentIsAvailable) return;
    this.loadData();
  },
  methods: {
    async loadData() {
      try {
        this.isLoading = true;
        await this.getData({ ignoreStored: true });
      } finally {
        this.isLoading = false;
      }
    },
    async reloadData() {
      try {
        this.isReloading = true;
        this.cancelRequests();
        return this.getData({ ignoreStored: true });
      } finally {
        this.isReloading = false;
      }
    },
    refreshData() {
      return this.getData({ ignoreStored: true });
    },
    getStoreParams(params: any): Promise<any> {
      return _.merge(
        {
          params: {
            with: this.invoicesWithParam,
            ...this.queryFilter,
            ...this.clientFilter,
            ...this.categoryFilter,
            ...this.contractFilter,
            ...this.contractProductFilter,
            ...this.stagedImportFilter,
            ...this.statusFilter,
            ...this.manualFilters
          }
        },
        params // Important – must merge last
      );
    },
    async doQuickSearch(value = "") {
      if (value === this.searchQuery) return Promise.resolve();
      await this.cancelRequests();
      this.searchQuery = value;
      return this.getData({
        ignoreStored: true,
        page: 1
      });
    },
    invoicesProvider(): IInvoicesProvider {
      return {
        availableFilters: this.filters,
        availableSorters: this.sorters,
        activeFilters: this.activeFilters,
        constQueryFilters: this.constQueryFilters,
        activeSort: this.table.sort,
        enableFiltering: this.enableFiltering,
        enableSorting: this.enableSorting,
        hasInvoices: !!this.table.data.length,
        isLoading: this.isLoading || this.table.loading,
        isReloading: this.isReloading,
        limit: this.table.limit,
        page: this.table.page,
        invoices: this.table.data as IInvoice[],
        searchQuery: this.searchQuery,
        table: this.table,
        total: this.table.total,
        guardFunctionalities: this.guardFunctionalities,
        clientId: this.clientId
      };
    }
  },
  render(h: CreateElement) {
    // Handle loading
    if (this.handleLoading && this.loading)
      return h("is-loading", { props: { if: true } });
    // Handle request error
    if (this.handleRequestError && this.requestError)
      return h("request-error", {
        props: { isLoading: this.table.loading && this.requestError },
        on: { click: () => this.loadData() }
      });
    return (
      this.$scopedSlots?.default &&
      this.$scopedSlots.default({
        $invoicesData: this.invoicesProvider(),
        $invoicesMethods: this.invoicesProviderMethods
      })
    );
  }
});
</script>
