import type { ICurrency } from "@/models/constants";
import type { IAccount } from "@/models/accounts";
import type { IClient } from "@/models/clients";
import type { IInvoice } from "@/models/invoices";
import type { IContract, IContractProduct } from "@/models/contracts";
import type { Location as VueLocation } from "vue-router";

export interface IExternalAuthRedirect {
  url: string;
  method?: string;
  fields?: { [key: string]: any };
  returnLocation?: VueLocation;
}

export enum PaymentTarget {
  CONTRACT_PRODUCT_CHANGE_PAYMENT_METHOD = "cpcpm",
  INVOICE_CHANGE_PAYMENT_METHOD = "icpm"
}

export interface IAutoPay {
  currencyId: ICurrency["id"];
  accountId: IAccount["id"];
  clientId: IClient["id"];
  invoiceId: IInvoice["id"];
  amount: IInvoice["total_amount_converted"];
  walletAmount: IInvoice["total_amount_converted"];
}

export interface IInitPay {
  invoiceId?: IInvoice["id"];
  clientId?: IInvoice["id"];
  contractId?: IContract["id"];
  productId?: IContractProduct["id"];
  target?: PaymentTarget;
}
