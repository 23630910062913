<template>
  <promotions-provider
    ref="promotionsProvider"
    :handle-loading="false"
    :manual-filters="promotionManualFilters"
    :limit="12"
  >
    <template v-slot:default="{ $promotionsData, $promotionsMethods }">
      <div>
        <div class="has-margin-bottom-50">
          <b-input
            v-focus="$store.getters['ui/isTabletPlus']"
            :value="$promotionsData.searchQuery"
            :placeholder="$t('_placeholder.product_quick_search')"
            :icon-right="$promotionsData.searchQuery ? 'close-circle' : null"
            :icon-right-clickable="true"
            @icon-right-click="$promotionsMethods.doQuickSearch()"
            @input="$promotionsData.debounceSearch"
          />
        </div>
        <filter-sort-controls
          v-if="
            $promotionsData.enableFiltering || $promotionsData.enableSorting
          "
          :active-filters="$promotionsData.activeFilters"
          :enable-filtering="$promotionsData.enableFiltering"
          :enable-sorting="$promotionsData.enableSorting"
          :filters="promotionAvailableFilters"
          :sorters="promotionAvailableSorters"
          :table="$promotionsData.table"
          class="has-margin-bottom-100"
          @onRefresh="$promotionsMethods.reloadData"
          @onFiltersChange="$promotionsMethods.onFiltersChange"
          @onSort="$promotionsMethods.onSort"
        />
        <div class="cprod-wells is-relative">
          <transition name="slide-down">
            <template
              v-if="!$promotionsData.isLoading && !$promotionsData.total"
            >
              <div class="u-ripple-table-empty">
                <div class="u-border-box has-padding-200 has-text-centered">
                  <p>
                    <i18n tag="strong" path="_sentence.no_promotions_msg" />
                  </p>
                  <p class="is-size-7 has-text-grey">
                    <i18n path="_sentence.no_results_matching_filters_msg" />
                  </p>
                  <p class="has-margin-top-100">
                    <router-link
                      class="button has-theme-brand"
                      :to="{ name: 'admin__addPromotion' }"
                    >
                      <i18n path="_action.create_promotion" />
                    </router-link>
                  </p>
                </div>
              </div>
            </template>
          </transition>
          <template v-if="$promotionsData.isLoading || !$promotionsData.total">
            <b-skeleton
              v-for="i in $promotionsData.hasPromotions
                ? $promotionsData.table.data.length
                : $store.getters['ui/isMobile']
                ? 2
                : 6"
              :key="`skeleton-${i}`"
              :animated="$promotionsData.isLoading"
            />
            <b-loading
              :active.sync="$promotionsData.isLoading"
              :is-full-page="false"
            />
          </template>
          <template v-if="!$promotionsData.isLoading && $promotionsData.total">
            <template v-for="promotion in $promotionsData.promotions">
              <promotion-list-item
                :key="promotion.id"
                :promotion="promotion"
                @restorePromotion="$promotionsMethods.restorePromotion"
                @deletePromotion="$promotionsMethods.deletePromotion"
              />
            </template>
          </template>
        </div>
        <u-pagination
          :current.sync="$promotionsData.page"
          :per-page="$promotionsData.limit"
          :total="$promotionsData.total"
          :visible-total="$promotionsData.table.data.length"
          @change-page="$promotionsMethods.onPageChange"
          @change-per-page="$promotionsMethods.onLimitChange"
        />
      </div>
    </template>
  </promotions-provider>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import PromotionsProvider from "@/components/app/admin/catalogue/promotions/promotionsProvider.vue";
import type { PropType } from "vue";
import type { IFilter, ISorter } from "@/models/tables";

const PromotionsListing = defineComponent({
  name: "PromotionsListing",
  components: {
    PromotionsProvider,
    "promotion-list-item": () =>
      import(
        "@/components/app/admin/catalogue/promotions/promotionListItem.vue"
      )
  },
  props: {
    routeFilters: {
      type: Function as PropType<
        (
          vm?: typeof PromotionsListing
        ) => Record<string, string | number | boolean>
      >,
      default: () => {}
    },
    parentFilters: {
      type: Object as PropType<Record<string, string | number | boolean>>,
      default: () => ({})
    },
    availableFilters: {
      type: Function as PropType<(vm?: typeof PromotionsListing) => IFilter[]>,
      default: () => {}
    },
    availableSorters: {
      type: Function as PropType<(vm?: typeof PromotionsListing) => ISorter[]>,
      default: () => {}
    }
  },
  computed: {
    promotionManualFilters() {
      return {
        ...this.parentFilters,
        ...this.routeFilters(this)
      };
    },
    promotionAvailableFilters() {
      return this.availableFilters(this);
    },
    promotionAvailableSorters() {
      return this.availableSorters(this);
    }
  },
  watch: {
    parentFilters: { handler: "onParentFiltersChange", deep: true }
  },
  methods: {
    onParentFiltersChange() {
      this.$refs.promotionsProvider.reloadData();
    }
  }
});

export default PromotionsListing;
</script>

<style lang="scss" scoped>
.cprod-wells {
  display: grid;
  gap: 0.75rem;
  grid-template-columns: 1fr;
  @include tablet {
    grid-template-columns: repeat(auto-fill, minmax(25em, 1fr));
  }
}
::v-deep .cprod-wells .b-skeleton {
  margin-top: 0;
  opacity: 0.75;
  .b-skeleton-item {
    height: 9.8125rem;
  }
}

.u-ripple-table-empty {
  z-index: 1;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90%;
  max-width: 22rem;
  box-shadow: 0 4px 16px 0 rgba($black, 0.1);
  & ~ .b-skeleton {
    opacity: 0.4;
    border-top-color: $light !important;
  }
}

.slide-down-enter-active,
.slide-down-leave-active {
  transition: all 360ms ease-out;
}
.slide-down-leave-active {
  transition-duration: 0s;
}
.slide-down-enter,
.slide-down-leave-to {
  margin-top: -1rem;
  opacity: 0;
}
</style>
