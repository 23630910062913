import { AdminRoutes } from "@/data/enums/router";
import i18n from "@/i18n";

export const clientSettingsOverview = () => [
  {
    label: i18n.t("_.profile"),
    to: (vm: any) => {
      return {
        name: AdminRoutes.CLIENT_SETTINGS_PROFILE,
        params: vm.$route.params
      };
    }
  },
  {
    label: i18n.t("_.security"),
    to: (vm: any) => {
      return {
        name: AdminRoutes.CLIENT_SETTINGS_SECURITY,
        params: vm.$route.params
      };
    }
  },
  {
    label: i18n.t("_.billing"),
    to: (vm: any) => {
      return {
        name: AdminRoutes.CLIENT_SETTINGS_BILLING,
        params: vm.$route.params
      };
    }
  }
];
