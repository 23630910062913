import type { IAccount } from "@/models/accounts";
import type { IAffiliatePendingCommission } from "@/models/affiliate";
import type { IBasket, IBasketProduct } from "@/models/baskets";
import type { IBrand } from "@/models/brands";
import type { IClient } from "@/models/clients";
import type { IAddress } from "@/models/addresses";
import type { ICompany } from "@/models/companies";
import type { IContract } from "@/models/contracts";
import type { ICurrency } from "@/models/constants";
import type { ICustomField } from "@/models/brands";
import type { IImport } from "@/models/imports";
import type { IOrg } from "@/models/organisation";
import type { IPayment } from "@/models/payment";
import type { IPromotion } from "@/models/promotions";
import type { IUser } from "@/models/users";
import type { IMetaData } from "@/models/metaData";

export interface IInvoiceContent {
  id: string;
  created_at: string;
  invoice_id: IInvoice["id"];
  updated_at: string;
  partial_amount_credited: number;
  partial_amount_credited_converted: number;
  partial_amount_credited_formatted: string;
  partial_amount_to_credit_converted: number;
  partial_amount_to_credit_formatted: string;
  content: {
    brand: IBrand;
    client: IClient;
    client_address: IAddress;
    client_company: ICompany;
    client_email: string;
    client_fields: [];
    client_id: IClient["id"];
    custom_fields: ICustomField[];
    id: string;
    image: string;
    number: number;
    products: IInvoiceProduct[];
    promotions: IPromotion[];
  };
}

export interface IInvoiceProduct extends IBasketProduct {
  attributes: IInvoiceProduct[];
  options: IInvoiceProduct[];
  partial_amount_credited: number;
  partial_amount_credited_converted: number;
  partial_amount_credited_formatted: string;
  partial_amount_to_credit_converted: number;
  partial_amount_to_credit_formatted: string;
}

export interface IInvoice extends IBasket {
  affiliate_commissions?: IAffiliatePendingCommission[];
  allow_product_credit: boolean;
  cancellation_datetime?: string | null;
  current_data: IInvoiceContent;
  data: IInvoiceContent[];
  delegate_related: boolean;
  is_consolidation: boolean;
  locked: string | null;
  net_amount_converted: number;
  object_meta?: IMetaData;
  partial_amount_credited: number;
  partial_amount_credited_converted: number;
  partial_amount_credited_formatted: string;
  partial_amount_to_credit_converted: number;
  partial_amount_to_credit_formatted: string;
  payment_currency?: ICurrency; // Requires relation
  payment_currency_id: ICurrency["id"];
  payments: IPayment[];
  product_upgrade_quantity?: number;
  products: IInvoiceProduct[];
  proforma: boolean;
  proforma_create_datetime: string;
  proforma_number: string;
  tax_amount_converted: number;
  to_be_credited: boolean;
}

export interface ILegacyInvoice {
  account_id: IAccount["id"];
  account?: IAccount;
  brand_id: IBrand["id"];
  brand?: IBrand;
  client_id: IClient["id"];
  client?: IClient;
  contract_id: IContract["id"];
  contract?: IContract;
  content: any;
  create_datetime: string | null;
  created_at: string;
  currency: ICurrency | null;
  currency_id: ICurrency["id"] | null;
  external_id: string | null;
  id: string;
  import?: IImport;
  import_id: IImport["id"];
  invoice_id: string;
  number: string;
  org?: IOrg;
  org_id: IOrg["id"];
  staged_import: boolean;
  status_id: string | null;
  total_amount: number | null;
  total_amount_converted: number | null;
  total_amount_formatted: string | null;
  updated_at: string;
  user_id: IUser["id"] | null;
}

export enum InvoiceCategoryCode {
  NEW_CONTRACT = "new_contract",
  ADDITIONAL_SERVICE = "additional_service",
  ONE_TIME_SERVICE = "one_time_service",
  MIGRATION = "migration_pro_rata",
  RECURRENT = "recurrent",
  CREDIT_NOTE = "credit_note",
  CREDIT_NOTE_FOR_REFUND = "credit_note_for_refund",
  CONSOLIDATION = "consolidation"
}

export enum CreditRefundOptionTypes {
  CREDIT_ENTIRE_INVOICE = "credit-entire-invoice",
  CREDIT_SELECT_ITEMS = "credit-select-items",
  REFUND_INVOICE_PAYMENTS = "refund-invoice-payments"
}

export type CreditRefundOption = {
  id: CreditRefundOptionTypes;
  title: string;
};

export interface IInvoiceUnpaid {
  invoice: IInvoice;
  total: number;
}
