import store from "@/store";
import _ from "@/boot/lodash";
import type { CreateElement } from "vue";
import type { Location, NavigationGuardNext, Route } from "vue-router";
import { BrandConfigKeys } from "@/data/constants";
import { ClientPrimaryTabigation } from "./menu";
import { ClientRoutes, GuestRoutes } from "@/data/enums/router";
import { Contexts } from "@/models/contexts";
import { GrantTypes } from "@/data/enums/tokens";

// tslint:disable-next-line:no-var-requires
export default _.union(require("./auth").default, require("./share").default, [
  {
    path: "/auth/verify",
    alias: "/verify",
    component: () => import("@/skeleton/client/loggedOut/index.vue"),
    beforeEnter: async (to: any, from: any, next: any) => {
      store.commit("user", {});
      next();
    },
    children: [
      {
        path: "",
        component: () => import("@/views/client/auth/verify/index.vue"),
        meta: {
          title: "_.verify_email"
        }
      }
    ]
  },
  {
    path: "/auth/verify-email",
    alias: "/verify-email",
    component: () => import("@/skeleton/client/loggedOut/index.vue"),
    beforeEnter: async (to: any, from: any, next: any) => {
      store.commit("user", {});
      next();
    },
    children: [
      {
        path: "",
        component: () => import("@/views/client/auth/verifyEmail/index.vue"),
        meta: {
          title: "_.verify_email"
        }
      }
    ]
  },
  {
    path: "/auth/transfer",
    component: {
      name: "AuthTransfer",
      render(h: CreateElement) {
        return h("loading", {
          props: {
            isAbsolute: true
          }
        });
      }
    },
    async beforeEnter(to: Route, from: Route, next: NavigationGuardNext) {
      const { code, redirect } = to?.query;

      /**
       * Here we define a fallback location in case things don't go to plan.
       * If specified we use the 'redirect' path, otherwise we default to
       * the client dashboard.
       */

      const fallback: Location =
        typeof redirect === "string"
          ? { path: redirect }
          : { name: ClientRoutes.DASHBOARD };

      /** If 'code' is missing, we abort and re-route to the fallback location. */

      if (!code) return next({ ...fallback, replace: true });

      /**
       * Here we attempt a login using the passed `code` and `auth_code` grant
       * type. We don't await this call as we want to allow the default routing
       * to continue and show the loading component (above). This is important for
       * when (if) the `handleLogin` call is executed as it assesses the 'from' path
       * to make sure we came from a valid '/auth/...' location. Awaiting would mean
       * the next() operation doesn't resolve in time and the login redirect breaks.
       * If the login attempt encounters any error (eg. an invalid code) we
       * re-route to the fallback location.
       */

      store
        .dispatch("auth/login", {
          context: Contexts.CLIENT,
          payload: {
            grant_type: GrantTypes.AUTH_CODE,
            code
          }
        })
        .catch(error => {
          store.dispatch("api/handleError", error);
          return next({ ...fallback, replace: true });
        });

      next();
    }
  },
  {
    name: ClientRoutes.RELAY_TOKEN,
    path: "/auth/relay",
    alias: "/relay",
    beforeEnter: async (to: any, from: any, next: any) => {
      if (window.opener) {
        await new Promise<void>(resolve => {
          const message = { source: "upmind", event: "client-ready" };
          let messageTimeout: any = null;
          const onMessage = async event => {
            if (!window.document.referrer.startsWith(event.origin)) return;
            if (_.get(event.data, "source") !== "upmind") return;
            if (_.get(event.data, "event") === "relay-token") {
              if (messageTimeout) clearTimeout(messageTimeout);
              store.commit("auth/client/reset");
              await store.dispatch("auth/saveToken", {
                token: _.get(event.data, "data"),
                context: Contexts.CLIENT
              });
              window.removeEventListener("message", onMessage, false);
              resolve();
            }
          };
          window.addEventListener("message", onMessage);
          window.opener.postMessage(message, "*");
          messageTimeout = setTimeout(resolve, 250);
        });
      }
      next({ name: ClientRoutes.DASHBOARD, replace: true });
    }
  },
  {
    path: "/",
    name: ClientRoutes.DEFAULT,
    component: () => import("@/skeleton/client/loggedIn/index.vue"),
    props: { routes: ClientPrimaryTabigation },
    beforeEnter: async (to: any, from: any, next: any) => {
      if (from.path.startsWith("/admin")) store.commit("ui/loading", true);
      // If logged out, route to default brand page
      if (!store.getters["auth/client/isAuthenticated"]) {
        if (to.path === "/") {
          if (from.path.endsWith("login")) store.dispatch("ui/endRouting");
          switch (
            store.getters["brand/config"][
              BrandConfigKeys.DEFAULT_CLIENT_HOMEPAGE
            ]
          ) {
            case "store":
              return next({
                name: GuestRoutes.STORE,
                query: to.query,
                replace: true
              });
            case "register":
              return next({
                name: ClientRoutes.REGISTER,
                query: to.query,
                replace: true
              });
            case "login":
            default:
              return next({ path: "/login", query: to.query, replace: true });
          }
        }
        return next({
          path: "/login",
          query: {
            ...to.query,
            redirect: to.fullPath
          }
        });
      }
      // If routing direct to "/" force to dashboard
      if (to.path.match(/^\/$/)?.length)
        return next({ name: ClientRoutes.DASHBOARD, replace: true });

      // Reset user – fixes behaviour issue with session modal
      store.commit("user", {});
      await store.dispatch("auth/client/prepare");
      if (store.getters["auth/client/isGuestCustomerAuthenticated"]) {
        return next({
          path: "/login",
          query: {
            ...to.query,
            redirect: to.fullPath
          }
        });
      }
      return next();
    },
    children: _.union(
      require("./account").default,
      require("./billing").default,
      require("./dashboard").default,
      require("./delegate_access").default,
      require("./products").default,
      require("./support").default,
      require("./custom").default
    )
  }
]);
