import type { IDataState } from "@/store/modules/data";
import type { ActionTree, GetterTree } from "vuex";
import type { IState } from "@/store";
import { AccessRoleTypes as ART, UUID } from "@/data/enums";
import type { CreateElement } from "vue";
import type { IUser } from "@/models/users";
import type { IClient } from "@/models/clients";
import i18n from "@/i18n";
import _ from "@/boot/lodash";

type IActor = Partial<IUser | IClient> | null;

const initialState = {} as IDataState;

const getters: GetterTree<IDataState, IState> = {
  isClientActor: () => (type: ART) => {
    return type === ART.CLIENT;
  },
  isApiActor: () => (type: ART, actor?: IUser) => {
    return type === ART.USER && !!actor?.api_user;
  },
  isStaffActor: () => (type: ART, id: string) => {
    return type === ART.USER && id !== UUID.SYS;
  },
  isSystemActor: () => (id: string) => {
    return id === UUID.SYS;
  },
  isUpmindStaffActor: () => (type: ART, actor: IActor) => {
    return type === ART.USER && actor?.org_id === UUID.SYS;
  },
  getActorString:
    (s, getters, rS, rootGetters) =>
    (type: ART, id: string, actor?: IActor) => {
      // No Actor (fallback to type)
      if (!actor) return _.upperFirst(type);
      // Client Actor
      if (getters.isClientActor(type))
        return rootGetters["isAdminContext"]
          ? i18n.t("_.actor_is_client", { actor: actor?.public_name })
          : actor?.public_name;
      // System Actor
      if (getters.isSystemActor(id))
        return actor?.public_name || i18n.t("_.system").toString();
      // API Actor
      if (getters.isApiActor(type, actor))
        return i18n.t("_.actor_is_api", { actor: actor?.public_name });
      // Upmind Staff Actor (conditional must come before normal staff user)
      if (getters.isUpmindStaffActor(type, actor))
        return i18n.t("_.actor_is_upmind_support", {
          actor: actor?.public_name
        });
      // Staff Actor
      if (getters.isStaffActor(type, id))
        return i18n.t("_.actor_is_staff", { actor: actor?.public_name });
      return "";
    },
  getActorComponent:
    (s, getters, rS, rootGetters) =>
    (type: ART, id: string, actor?: IActor) => {
      // No Actor (fallback to type)
      if (!actor)
        return { render: (h: CreateElement) => h("span", _.upperFirst(type)) };
      // Client Actor
      if (getters.isClientActor(type))
        return {
          render: (h: CreateElement) =>
            rootGetters["isAdminContext"]
              ? // Admin Context
                h("i18n", { props: { path: "_.actor_is_client" } }, [
                  h(
                    "u-link",
                    {
                      slot: "actor",
                      props: {
                        to: {
                          name: "adminClient",
                          params: { clientId: id }
                        }
                      }
                    },
                    actor?.public_name
                  )
                ])
              : // Client Context
                h("span", actor?.public_name)
        };
      // System Actor
      if (getters.isSystemActor(id))
        return {
          render: (h: CreateElement) =>
            h("span", actor?.public_name || i18n.t("_.system").toString())
        };
      // API Actor
      if (getters.isApiActor(type, actor))
        return {
          render: (h: CreateElement) =>
            h(
              "span",
              i18n.t("_.actor_is_api", { actor: actor?.public_name }).toString()
            )
        };
      // Upmind Staff Actor (conditional must come before normal staff user)
      if (getters.isUpmindStaffActor(type, actor))
        return {
          render: (h: CreateElement) =>
            h(
              "span",
              i18n
                .t("_.actor_is_upmind_support", { actor: actor?.public_name })
                .toString()
            )
        };
      // Staff Actor
      if (getters.isStaffActor(type, id))
        return {
          render: (h: CreateElement) =>
            rootGetters["isAdminContext"]
              ? // Admin Context
                h("i18n", { props: { path: "_.actor_is_staff" } }, [
                  h(
                    "u-link",
                    {
                      slot: "actor",
                      props: {
                        to: {
                          name: "adminUserActivityLog",
                          params: { userId: id }
                        }
                      }
                    },
                    actor?.public_name
                  )
                ])
              : // Client Context
                h(
                  "span",
                  i18n
                    .t("_.actor_is_staff", { actor: actor?.public_name })
                    .toString()
                )
        };
      return "";
    }
};

const actions: ActionTree<IDataState, IState> = {
  openLogModal: ({ dispatch }, payload) => {
    dispatch(
      "ui/open/slideModal",
      {
        config: {
          component: () =>
            import("@/components/app/global/logs/viewLogModal.vue"),
          ...payload
        }
      },
      { root: true }
    );
  }
};

export default {
  namespaced: true,
  state: initialState,
  actions,
  getters
};
