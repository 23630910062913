import { DocCategories } from "@/data/enums/docs";

export default [
  {
    path: "account",
    components: {
      default: () => import("@/views/admin/account/index.vue")
    },
    props: {
      default: {
        routes: require("./menu").AccountRoutes
      }
    },
    meta: {
      title: "_.my_account"
    },
    children: [
      {
        path: "",
        name: "admin__account",
        redirect: "profile"
      },
      {
        path: "profile",
        name: "adminAccountProfile",
        component: () => import("@/views/admin/account/profile/index.vue"),
        meta: {
          title: "_.profile",
          backTo: "_.profile",
          categorySlug: DocCategories.MANAGING_YOUR_ACCOUNT
        }
      },
      {
        path: "email-history",
        name: "adminAccountEmailHistory",
        component: () => import("@/views/admin/account/emailHistory/index.vue"),
        meta: {
          title: "_.email_history",
          backTo: "_.email_history",
          auth: ["list_email_messages"],
          categorySlug: DocCategories.MANAGING_YOUR_ACCOUNT
        }
      },
      {
        path: "security",
        name: "adminAccountSecurity",
        component: () => import("@/views/admin/account/security/index.vue"),
        meta: {
          title: "_.security",
          backTo: "_.security",
          categorySlug: DocCategories.MANAGING_YOUR_ACCOUNT
        }
      },
      {
        path: "activity-log",
        name: "adminAccountActivityLog",
        component: () => import("@/views/admin/account/activityLog/index.vue"),
        meta: {
          title: "_.activity_log",
          backTo: "_.activity_log",
          auth: ["list_user_hook_logs"],
          categorySlug: DocCategories.MANAGING_YOUR_ACCOUNT
        }
      },
      {
        path: "*",
        component: () => import("@/views/error/index.vue"),
        props: { code: 404 }
      }
    ]
  }
];
