import Vue from "vue";
import type { ActionTree, GetterTree, MutationTree } from "vuex";
import type { IState } from "@/store";
import { Methods } from "@/models/methods";
import type { AxiosResponse } from "axios";
import type { IStatus } from "@/models/statuses";
import _ from "@/boot/lodash";
import { BrandConfigKeys } from "@/data/constants";

import type {
  IBillingCycle,
  ICountry,
  ICurrency,
  ILanguage,
  IRegion
} from "@/models/constants";
import type { ITaxBusinessType } from "@/models/tax";
import { DataModules } from "../data/modules";
import i18n from "@/i18n";
import type { ITicketDepartment } from "@/models/tickets";
import type { UpmindObjectTypes } from "@/data/enums/objects";
import type { ApiPathGetter } from "@/models/api";

enum PaymentTermDesignations {
  MONTHLY = "monthly",
  ANNUALLY = "annually"
}

export interface IConstantsState {
  countries: ICountry[];
  regions: Record<string, IRegion>;
  currencies: ICurrency[];
  languages: ILanguage[];
  billingCycles: IBillingCycle[];
  statuses: {
    ticket: IStatus[];
    invoice: IStatus[];
  };
  departments: ITicketDepartment[];
  systemIPAddresses: string[];
  taxBusinessTypes: ITaxBusinessType[];
}

export const initialState = (): IConstantsState => {
  return {
    countries: [],
    regions: {},
    currencies: [],
    languages: [],
    billingCycles: [],
    statuses: {
      ticket: [],
      invoice: []
    },
    departments: [],
    systemIPAddresses: [],
    taxBusinessTypes: []
  };
};

const getters: GetterTree<IConstantsState, IState> = {
  apiPath:
    (s, g, rS, { isAdminContext, isMockClientContext }): ApiPathGetter =>
    slug => {
      const admin = `api/admin/${slug}`;
      const client = `api/${slug}`;
      const contextual =
        isAdminContext && !isMockClientContext() ? admin : client;
      return { client, admin, contextual };
    },
  getBillingCycleName: (state, g, rS, rootGetters) => (months: number) => {
    const billingCycle: IBillingCycle | undefined = _.find(
      state.billingCycles,
      ["months", months]
    );

    return rootGetters["brand/config"][
      BrandConfigKeys.BASKET_PAYMENT_TERM_DESCRIPTIONS
    ] === PaymentTermDesignations.MONTHLY || !billingCycle
      ? i18n.tc("_plurals.every_object_months", months)
      : billingCycle.name;
  },
  getCurrency: state => (id: ICurrency["id"]) => {
    return _.find(state.currencies, ["id", id]);
  },
  getRegions: state => (countryCode: string) => {
    return countryCode ? _.get(state, `regions.${countryCode}`, []) : [];
  },
  currencies:
    state =>
    ({ baseOnly = false }) => {
      if (!baseOnly) return state.currencies;
      return _.filter(state.currencies, currency => currency.base);
    }
};

const actions: ActionTree<IConstantsState, IState> = {
  getCountries: ({ state, commit, dispatch, getters }, cached = true) => {
    return new Promise((resolve, reject) => {
      if (state.countries.length && cached) {
        return resolve(state.countries);
      }
      dispatch(
        "api/call",
        {
          method: Methods.GET,
          path: getters.apiPath("countries").contextual,
          requestConfig: {
            params: {
              limit: 0
            }
          }
        },
        { root: true }
      )
        .then((response: AxiosResponse) => {
          commit("setCountries", {
            data: response.data
          });
          return resolve(state.countries);
        })
        .catch(reject);
    });
  },
  getCurrencies: (
    { state, commit, dispatch, getters },
    { isLoggedOut } = { isLoggedOut: false },
    cached = true
  ) => {
    return new Promise((resolve, reject) => {
      if (state.currencies.length && cached) {
        return resolve(state.currencies);
      }

      dispatch(
        "api/call",
        {
          method: Methods.GET,
          path: isLoggedOut
            ? getters.apiPath("currencies").client
            : getters.apiPath("currencies").contextual,
          requestConfig: {
            params: {
              limit: 0
            }
          }
        },
        { root: true }
      )
        .then((response: AxiosResponse) => {
          commit("setCurrencies", {
            data: response.data
          });
          resolve(response.data);
        })
        .catch(reject);
    });
  },
  getLanguages: ({ state, commit, dispatch, getters }, cached = true) => {
    return new Promise((resolve, reject) => {
      if (state.languages.length && cached) {
        return resolve(state.languages);
      }
      dispatch(
        "api/call",
        {
          method: Methods.GET,
          path: getters.apiPath("languages").contextual,
          requestConfig: {
            params: {
              limit: 0
            }
          }
        },
        { root: true }
      )
        .then((response: AxiosResponse) => {
          commit("setLanguages", {
            data: response.data
          });
          resolve(response.data);
        })
        .catch(reject);
    });
  },
  getStatuses: (
    { dispatch, state, commit },
    objectType: UpmindObjectTypes,
    cached = true
  ) => {
    return new Promise((resolve, reject) => {
      if (_.get(state.statuses, objectType, []).length && cached) {
        return resolve(state.statuses[objectType]);
      }

      dispatch(
        `data/${DataModules.STATUSES}/listByType`,
        {
          type: objectType,
          returnData: true,
          storeData: false
        },
        { root: true }
      )
        .then(data => {
          commit("setStatuses", {
            objectType,
            data
          });
          resolve(data);
        })
        .catch(reject);
    });
  },
  getDepartments: async (
    { dispatch, state, commit },
    { fromCache = true, ignoreStored = false, params = {} }
  ) => {
    if (fromCache && !!state.departments.length) return state.departments;
    const data = await dispatch(
      `data/${DataModules.TICKETS_DEPARTMENTS}/list`,
      { storeData: false, params },
      { root: true }
    );
    if (fromCache || ignoreStored) commit("setDepartments", { data });
    return data;
  },
  getBillingCycles: (
    { state, commit, dispatch, getters },
    options?: { fromCache?: boolean }
  ) => {
    return new Promise((resolve, reject) => {
      // By default, take cached results from store
      const fromCache = options?.fromCache ?? true;
      // Return cached results, if appliable
      if (fromCache && !!state.billingCycles.length)
        return resolve(state.billingCycles);

      dispatch(
        "api/call",
        {
          method: Methods.GET,
          path: getters.apiPath("billing_cycles").contextual,
          requestConfig: {
            params: {
              limit: 0
            }
          }
        },
        { root: true }
      )
        .then((response: AxiosResponse) => {
          commit("setBillingCycles", {
            data: response.data
          });
          resolve(response.data);
        })
        .catch(reject);
    });
  },
  getRegions: async (
    { state, commit, dispatch, getters },
    payload: {
      countryCode: ICountry["code"];
      countryId: ICountry["id"];
    },
    cached = true
  ) => {
    const { countryCode, countryId } = payload;
    if (!countryCode || !countryId) return;
    return new Promise((resolve, reject) => {
      if (!_.isEmpty(state.regions[countryCode]) && cached)
        return resolve(state.regions[countryCode]);
      dispatch(
        "api/call",
        {
          method: Methods.GET,
          path: `${
            getters.apiPath("countries").contextual
          }/${countryId}/regions`,
          requestConfig: {
            params: {
              limit: 0
            }
          }
        },
        { root: true }
      )
        .then((response: AxiosResponse) => {
          commit("setRegion", {
            countryCode,
            data: response.data
          });
          resolve(response.data);
        })
        .catch(reject);
    });
  },
  getSystemIPAddresses: (
    { state, commit, dispatch, getters },
    cached = true
  ) => {
    return new Promise((resolve, reject) => {
      if (state.systemIPAddresses.length && cached) {
        return resolve(state.systemIPAddresses);
      }
      dispatch(
        "api/call",
        {
          method: Methods.GET,
          path: getters.apiPath("system/ip_addresses").admin,
          requestConfig: {
            params: {
              limit: 0
            }
          }
        },
        { root: true }
      )
        .then((response: AxiosResponse) => {
          commit("setSystemIPAddresses", response.data);
          resolve(response.data);
        })
        .catch(reject);
    });
  },
  getTaxBusinessTypes: (
    { state, commit, dispatch, getters },
    cached = true
  ) => {
    return new Promise((resolve, reject) => {
      if (state.taxBusinessTypes.length && cached) {
        return resolve(state.taxBusinessTypes);
      }
      dispatch(
        "api/call",
        {
          method: Methods.GET,
          path: getters.apiPath("tax_business_types").admin,
          requestConfig: {
            params: {
              limit: 0
            }
          }
        },
        { root: true }
      )
        .then((response: AxiosResponse) => {
          commit("setTaxBusinessTypes", {
            data: response.data
          });
          resolve(response.data);
        })
        .catch(reject);
    });
  },
  binCurrencies: ({ commit }): Promise<void> => {
    return new Promise(resolve => {
      commit("resetCurrencies");
      resolve();
    });
  },
  binLanguages: ({ commit }): Promise<void> => {
    return new Promise(resolve => {
      commit("resetLanguages");
      resolve();
    });
  },
  binCountries: ({ commit }): Promise<void> => {
    return new Promise(resolve => {
      commit("resetCountries");
      resolve();
    });
  },
  binDepartments: ({ commit }): Promise<void> => {
    return new Promise(resolve => {
      commit("resetDepartments");
      resolve();
    });
  },
  binStatuses: ({ commit }): Promise<void> => {
    return new Promise(resolve => {
      commit("resetStatuses");
      resolve();
    });
  },
  binBillingCycles: ({ commit }): Promise<void> => {
    return new Promise(resolve => {
      commit("resetBillingCycles");
      resolve();
    });
  },
  binSystemIPAddresses: ({ commit }): Promise<void> => {
    return new Promise(resolve => {
      commit("resetSystemIPAddresses");
      resolve();
    });
  },
  binTaxBusinessTypes: ({ commit }) => {
    return new Promise<void>(resolve => {
      commit("resetTaxBusinessType");
      resolve();
    });
  }
};

const mutations: MutationTree<IConstantsState> = {
  setCountries: (state, results: { data: ICountry[] }) => {
    Vue.set(state, "countries", results.data);
  },
  setCurrencies: (state, results: { data: ICurrency[] }) => {
    Vue.set(state, "currencies", results.data);
  },
  setLanguages: (state, results: { data: ILanguage[] }) => {
    Vue.set(state, "languages", results.data);
  },
  setStatuses: (
    state,
    results: { data: IStatus[]; objectType: UpmindObjectTypes }
  ) => {
    Vue.set(state.statuses, results.objectType, results.data);
  },
  setDepartments: (state, results: { data: ITicketDepartment[] }) => {
    Vue.set(state, "departments", results.data);
  },
  setBillingCycles: (state, results: { data: IBillingCycle[] }) => {
    Vue.set(state, "billingCycles", results.data);
  },
  setRegion: (state, results: { countryCode: string; data: IRegion }) => {
    Vue.set(state.regions, results.countryCode, results.data);
  },
  setTaxBusinessTypes: (state, results: { data: ITaxBusinessType[] }) => {
    Vue.set(state, "taxBusinessTypes", results.data);
  },
  setSystemIPAddresses: (state, results: string[]) => {
    Vue.set(state, "systemIPAddresses", results);
  },
  resetCountries: state => {
    Vue.set(state, "countries", initialState().countries);
  },
  resetCurrencies: state => {
    Vue.set(state, "currencies", initialState().currencies);
  },
  resetDepartments: state => {
    Vue.set(state, "departments", initialState().departments);
  },
  resetLanguages: state => {
    Vue.set(state, "languages", initialState().languages);
  },
  resetStatuses: state => {
    Vue.set(state, "statuses", initialState().statuses);
  },
  resetBillingCycles: state => {
    Vue.set(state, "billingCycles", initialState().billingCycles);
  },
  resetSystemIPAddresses: state => {
    Vue.set(state, "systemIPAddresses", initialState().systemIPAddresses);
  },
  resetTaxBusinessTypes: state => {
    Vue.set(state, "taxBusinessTypes", initialState().taxBusinessTypes);
  }
};

export default {
  namespaced: true,
  state: initialState(),
  actions,
  getters,
  mutations
};
