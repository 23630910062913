import _ from "@/boot/lodash";
import ClientsListing from "@/components/app/admin/clients/clientsListing.vue";
import SegmentResultsListing from "@/components/app/admin/segments/segmentResultsListing.vue";
import { FraudStatus } from "@/data/enums";
import { DocCategories } from "@/data/enums/docs";
import { ISegmentTypes } from "@/data/enums/segments";
import { RegexMatch } from "@/data/enums/router";
import type { Route, RouteConfig } from "vue-router";

import {
  NotGuestParam,
  UnverifiedParam,
  FromLast30DaysParam,
  FraudStatusParam,
  DefaultFilters
} from "@/data/filters/client";
import { DefaultSorters } from "@/data/sorters/client";
import { ListDisplayMode } from "@/data/constants";
import { UserMetaKeys } from "@/data/enums/users";

export default _.union(
  require("./client").default,
  require("./affiliates").default,
  require("./bulkActions").default,
  [
    {
      path: "clients",
      name: "adminClients",
      redirect: { name: "adminClientsOverview" },
      component: () => import("@/views/admin/clients/index.vue"),
      props: {
        routes: require("./menu").ClientsTabigation
      },
      meta: {
        auth: ["list_clients"],
        breadcrumb: {
          label: "_.clients"
        }
      },
      children: [
        {
          path: "",
          redirect: "overview"
        },
        {
          path: "overview",
          name: "adminClientsOverview",
          component: () => import("@/views/admin/clients/overview/index.vue"),
          meta: {
            auth: ["list_clients"],
            categorySlug: DocCategories.USING_UPMIND,
            docsSlugs: ["client-management"]
          }
        },
        {
          path: "list",
          component: () => import("@/views/admin/clients/list/index.vue"),
          meta: {
            auth: ["list_clients"]
          },
          children: [
            {
              path: "",
              name: "adminClientsList",
              redirect: { name: "adminClients_all" },
              meta: {
                categorySlug: DocCategories.USING_UPMIND,
                docsSlugs: ["client-management"]
              },
              component: {
                name: "AdminClients",
                template: "<router-view />"
              },
              children: [
                {
                  path: "all",
                  name: "adminClients_all",
                  component: ClientsListing,
                  meta: {
                    categorySlug: DocCategories.USING_UPMIND,
                    docsSlugs: ["client-management"]
                  }
                },
                {
                  path: "excluding-guests",
                  name: "adminClients_excludingGuests",
                  component: ClientsListing,
                  props: () => ({
                    manualFilters: () => ({ ...NotGuestParam() })
                  }),
                  meta: {
                    categorySlug: DocCategories.USING_UPMIND,
                    docsSlugs: ["client-management"]
                  }
                },
                {
                  path: "unverified",
                  name: "adminClients_unverified",
                  component: ClientsListing,
                  props: () => ({
                    manualFilters: () => ({ ...UnverifiedParam() })
                  }),
                  meta: {
                    categorySlug: DocCategories.USING_UPMIND,
                    docsSlugs: ["client-management"]
                  }
                },
                {
                  path: "last30Days",
                  name: "adminClients_last30Days",
                  component: ClientsListing,
                  props: () => ({
                    manualFilters: () => ({ ...FromLast30DaysParam() })
                  }),
                  meta: {
                    categorySlug: DocCategories.USING_UPMIND,
                    docsSlugs: ["client-management"]
                  }
                },
                {
                  path: "pending-review",
                  name: "adminClients_pendingReview",
                  component: ClientsListing,
                  props: () => ({
                    availableFilters: () =>
                      DefaultFilters().filter(
                        i => !["fraud_status"].includes(i.key)
                      ),
                    manualFilters: () => ({
                      ...FraudStatusParam(FraudStatus.REVIEW)
                    })
                  }),
                  meta: {
                    categorySlug: DocCategories.USING_UPMIND,
                    docsSlugs: ["client-management"]
                  }
                },
                {
                  path: "blocked",
                  name: "adminClients_blocked",
                  component: ClientsListing,
                  props: () => ({
                    availableFilters: () =>
                      DefaultFilters().filter(
                        i => !["fraud_status"].includes(i.key)
                      ),
                    manualFilters: () => ({
                      ...FraudStatusParam(FraudStatus.FRAUD)
                    })
                  }),
                  meta: {
                    categorySlug: DocCategories.USING_UPMIND,
                    docsSlugs: ["client-management"]
                  }
                }
              ]
            },
            {
              path: `segments/:segmentId(${RegexMatch.UUID})`,
              name: "adminClientsSegments",
              component: {
                name: "AdminClientsSegmentList",
                template: `<router-view  :key="$route.params.segmentId"/>`
              },
              redirect: { name: "adminClients_segment" },
              meta: {
                auth: ["list_segments"],
                requiresBrand: true
              },
              children: [
                {
                  path: "",
                  name: "adminClients_segment",
                  component: SegmentResultsListing,
                  props: (route: Route) => ({
                    defaultDisplayMode: ListDisplayMode.GRID,
                    displayModeStoragePath:
                      UserMetaKeys.UI_CLIENTS_DISPLAY_MODE,
                    segmentTypeHint: ISegmentTypes.CLIENT,
                    segmentId: route.params.segmentId,
                    newSegmentRouteName: "adminClientsSegments",
                    availableSorters: DefaultSorters,
                    availableFilters: DefaultFilters
                  })
                }
              ]
            }
          ]
        },
        {
          path: "*",
          component: () => import("@/views/error/index.vue"),
          props: { code: 404 }
        }
      ]
    }
  ] as RouteConfig[]
);
