<template>
  <ValidationObserver ref="observer" slim>
    <div class="card">
      <div class="has-padding-y-200 has-padding-x-300 has-text-centered">
        <p class="subtitle has-text-weight-semibold has-margin-bottom-50">
          <i18n path="_.completing_payment" />
        </p>
        <i18n
          tag="p"
          path="_sentence.completing_payment"
          class="has-text-grey"
        />
        <b-progress class="has-margin-top-100" />
      </div>
    </div>
  </ValidationObserver>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { mapState } from "vuex";
import PaymentProvider from "@/components/app/global/payments/paymentProvider.vue";
import { QUERY_PARAMS } from "@/data/constants";
import { DataModules } from "@/store/modules/data/modules";
import { PaymentMethodType } from "@/models/selectPaymentMethod";
import type { PropType } from "vue";
import type { IClient } from "@/models/clients";
import type { IAccount } from "@/models/accounts";
import type { ICurrency } from "@/models/constants";
import type { IPaymentDetail } from "@/models/paymentDetails";
import type { StoredCardData } from "@/models/selectPaymentMethod";
import type { IState } from "@/store";

export default defineComponent({
  name: "TopUpWalletAutoPayModal",
  mixins: [PaymentProvider],
  props: {
    amount: { type: Number },
    clientId: { type: String as PropType<IClient["id"]> },
    currencyId: { type: String as PropType<ICurrency["id"]> },
    accountId: { type: String as PropType<IAccount["id"]> },
    preselectedStoredPaymentMethodId: {
      type: String as PropType<IPaymentDetail["id"]>
    }
  },
  data: () => ({
    isProcessing: true
  }),
  computed: {
    ...mapState({
      paymentDetail(state: IState, getters): IPaymentDetail {
        return getters[`data/single`]({
          storeModule: DataModules.CLIENTS_PAYMENT_DETAILS,
          scope: this.dataScope
        });
      }
    }),
    totalAmountToPay() {
      return this.$_.toNumber(this.amount);
    },
    dataScope() {
      return `$client_${this.clientId}_topup`;
    },
    additionalPaymentData() {
      const returnUrl = window.location.href.split("?")[0];
      const returnUrlEncodedSuccess = encodeURIComponent(
        `${returnUrl}?${QUERY_PARAMS.PAYMENT_SUCCESS}=true`
      );
      const returnUrlEncodedFail = encodeURIComponent(
        `${returnUrl}?${QUERY_PARAMS.PAYMENT_SUCCESS}=false`
      );
      return {
        client_id: this.clientId,
        account_id: this.accountId,
        currency_id: this.currencyId,
        return_url: `?${QUERY_PARAMS.SUCCESS}=${returnUrlEncodedSuccess}&${QUERY_PARAMS.FAILED}=${returnUrlEncodedFail}`,
        cancel_url: returnUrl
      };
    }
  },
  async created() {
    try {
      await Promise.all([this.waitMinimum(), this.initPayment()]);
    } finally {
      this.isProcessing = false;
    }
  },
  beforeDestroy() {
    this.$store.dispatch("data/binSingle", {
      storeModule: DataModules.CLIENTS_PAYMENT_DETAILS,
      scope: this.dataScope,
      vm: this
    });
  },
  methods: {
    waitMinimum() {
      return new Promise(resolve => {
        setTimeout(() => {
          resolve(true);
        }, 2 * 1000);
      });
    },
    async initPayment() {
      await this.getPaymentDetail();
      await this.topUp();
    },
    getPaymentDetail() {
      return this.$store.dispatch(
        `data/${DataModules.CLIENTS_PAYMENT_DETAILS}/get`,
        {
          clientId: this.clientId,
          scope: this.dataScope,
          paymentDetailId: this.preselectedStoredPaymentMethodId
        }
      );
    },
    async topUp() {
      if (!this.preselectedStoredPaymentMethodId) {
        this.isProcessing = false;
        this.$emit("close");
        return;
      }
      try {
        await this.$store.dispatch(
          `data/${DataModules.CLIENTS_PAYMENT_DETAILS}/get`,
          {
            clientId: this.clientId,
            paymentDetailId: this.preselectedStoredPaymentMethodId
          }
        );

        this.paymentMethod = {
          amount: this.amount || 0,
          type: PaymentMethodType.STORED_CARD,
          data: {
            payment_details_id: this.preselectedStoredPaymentMethodId
          } as StoredCardData
        };

        await this.preparePaymentData(this.clientId);
        // Execute payment
        await this.executePayment();
        // Emit 'success'
        this.$emit("success");
        this.$emit("close");
      } catch (error) {
        this.$emit("error");
        this.$emit("close");
        this.$store.dispatch("api/handleValidationError", {
          error,
          vm: this
        });
      }
    }
  }
});
</script>
