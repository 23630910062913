import type { ICurrency } from "@/models/constants";
import type { IBrand } from "@/models/brands";
import type { IClient } from "@/models/clients";
import type { IStatus } from "@/models/statuses";
import type { IAddress } from "@/models/addresses";
import type { IUser } from "@/models/users";
import type { IPaymentDetail } from "@/models/paymentDetails";
import type { IGateway } from "@/models/gateways";
import type { IPromotion } from "@/models/promotions";
import type { IFraudObject } from "@/models/fraud";
import i18n from "@/i18n";
import type {
  IPricelist,
  IProduct,
  IProductCategory,
  IProductMigration
} from "@/models/products";
import type { IInvoice } from "@/models/invoices";
import type { PriceType } from "@/data/enums/contractProducts";
import type { IAccount } from "@/models/accounts";
import type { IImport } from "@/models/imports";
import type {
  IProvisionCategory,
  IProvisionProvider,
  IProvisionRequest
} from "@/models/provisioning";
import type { IRetentionReason } from "@/models/retentionReasons";
import type { Component, CreateElement, VNode } from "vue";
import type { ICompany } from "@/models/companies";
import type { BrandTaxType } from "@/data/enums";

export interface IContract extends IFraudObject {
  account_id: IAccount["id"];
  activation_date: string | null;
  address_id: IAddress["id"];
  address: IAddress;
  billing_cycle_months: number;
  brand: IBrand;
  brand_id: IBrand["id"];
  cancellation_date: null | string;
  cancellation_reason: null | string;
  cancellation_request: IContractCancellationRequest;
  client: IClient;
  client_id: IClient["id"];
  company_id: ICompany["id"];
  created_at: string;
  currency: ICurrency;
  currency_id: ICurrency["id"];
  end_date: string;
  end_recurring: null | string;
  gateway: IGateway;
  gateway_id: IGateway["id"];
  id: string;
  moved: boolean;
  moved_to_contract: IContract;
  main_invoice_id: IInvoice["id"];
  main_invoice_number: IInvoice["number"];
  main_service_id: string;
  name: null | string;
  next_due_date: string;
  next_invoice_date: string;
  partially_moved: boolean;
  payment_details: IPaymentDetail;
  payment_details_id: IPaymentDetail["id"];
  pricelist_id: IPricelist["id"];
  products: IContractProduct[];
  promotion_code: null | string;
  promotion_id: null | IPromotion["id"];
  promotions: IContractPromotion[];
  recurring: number;
  reseller_account_id: null | IAccount["id"];
  start_date: string;
  start_recurring: null | string;
  status: IStatus;
  tax_type: BrandTaxType;
  total_amount: number;
  total_amount_formatted: string;
  total_recurrent_amount: string;
  total_recurrent_amount_formatted: string;
  updated_at: string;
  user: IUser;
  user_id: IUser["id"];
}

export interface IContractCancellationRequest {
  contract?: IContract; // Requires relation
  contract_id: IContract["id"];
  contract_product?: IContractProduct; // Requires relation
  contract_product_id: IContractProduct["id"];
  created_at: string;
  deleted_at: string;
  id: string;
  reason: string;
  status?: IStatus;
  status_id: IStatus["id"];
  type: null;
  updated_at: string;
}

export type IContractProductSettingsForm = Pick<
  IContractProduct,
  "create_invoice_term" | "payment_days_term"
>;

export interface IContractPromotion {
  autoapply: number;
  contract_id: IContract["id"];
  created_at: string;
  deleted_at: null | string;
  id: string;
  invoice_id: IInvoice["id"];
  promotion: IPromotion;
  promotion_id: IPromotion["id"];
  updated_at: string;
}

export interface IContractProduct {
  account_id?: IAccount["id"];
  activation_date: string | null;
  allowed_migrations: IProductMigration[]; // Requires relation `products.allowed_migrations`
  amount: number;
  amount_formatted: string;
  attributes: IContractProduct[];
  auto_create_renew_invoice: boolean;
  base_currency_code: string;
  base_price: number;
  base_price_currency_id: ICurrency["id"];
  base_price_exchange_rate: number;
  base_price_exchange_rate_formatted: string;
  base_price_formatted: string;
  billing_cycle_months: number;
  calculated_cancel_date?: null | string;
  calculated_close_date?: null | string;
  calculated_suspend_date?: null | string;
  can_create_next_invoice?: boolean;
  can_modify?: boolean; // Requires relation `products.paid_initial_invoice`
  cancelled_date: null | string;
  client?: IClient; // Requires relation
  client_id?: IClient["id"]; // IF manually injected (eg. { ...cProd, client_id: "..." })
  client_label?: string;
  clients?: IClient[]; // Requires relation
  closed_date?: null | string;
  configuration_net_amount_discounted_formatted: string;
  configuration_net_amount_formatted: string;
  configuration_total_amount_formatted: string;
  configuration_total_discounted_amount_formatted: string;
  configuration_total_recurring_amount_formatted: string;
  configuration_total_recurring_net_amount_formatted: string;
  contract_id: IContract["id"];
  contract_request: IContractCancellationRequest;
  cost: number;
  cost_currency_code: string;
  cost_currency_id: ICurrency["id"];
  cost_exchange_rate: number;
  cost_exchange_rate_formatted: string;
  cost_formatted: string;
  create_invoice_term: number;
  created_at: string;
  deleted_at: null | string;
  description: string;
  discount_amount: number;
  discount_amount_formatted: string;
  due_date: null | string;
  end_date: null | string;
  id: string;
  import_id: IImport["id"];
  in_trial: boolean;
  invoice_consolidation_enabled: number;
  invoice_id: IInvoice["id"];
  invoice_product_id: IProduct["id"];
  is_delegated_object: boolean;
  last_invoice_id: IInvoice["id"];
  main_contracts_product_id: null | IContractProduct["id"];
  main_invoice_id: IInvoice["id"];
  main_invoice_number: IInvoice["number"];
  manual_status: boolean;
  manual_status_reason: string | null;
  moved: boolean;
  moved_to_contract_product: IContractProduct;
  moved_to_contract_product_id: IContractProduct["id"];
  name: string;
  next_due_date: null | string;
  next_invoice_date: null | string;
  not_cancel_until: null | string;
  not_close_until: null | string;
  not_suspend_until: null | string;
  options: IContractProduct[];
  payment_days_term: number;
  partially_moved?: boolean;
  previous_retention_reason_id: IRetentionReason["id"];
  previous_retention_status_id: IStatus["id"];
  price_type: null | PriceType;
  pro_rata_pending: boolean;
  product?: IProduct | null;
  product_group_id: null | string;
  product_id: IProduct["id"];
  product_name: string;
  product_set_id: null | string;
  provision_center_id: null | string;
  provision_configuration?: IProvisionCategory; // Requires relation
  provision_configuration_id: IProvisionCategory["id"];
  provision_fields: any[];
  provision_lock: number;
  provision_provider?: IProvisionProvider; // Requires relation
  provision_provider_id: null | string;
  provision_server_id: null | string;
  provision_setup_fields_confirmed: boolean;
  quantity: number;
  recommended_money_back_period: number | null;
  renew: boolean;
  renew_date: null | string;
  retention_reason_id: IRetentionReason["id"];
  retention_status_id: IStatus["id"];
  selling_price: number;
  selling_price_formatted: string;
  service_identifier: string;
  staged_import: boolean;
  start_date: string;
  status?: IStatus;
  status_id: IStatus["id"];
  suspended_date?: null | string;
  tax_amount: number;
  tax_amount_formatted: string;
  total_amount: number;
  trial_duration: number;
  trial_end_action: number;
  trial_end_action_time: number | null;
  trial_end_date: string;
  trial_pre_expire_notification: number;
  trial_pre_expire_notification_date: string;
  unpaid_recurring_invoices?: IInvoice[]; // Requires relation
  unresolved_provision_requests?: IProvisionRequest[]; // Requires relation
  updated_at: string;
}

export interface ICProdGroup {
  category: IProductCategory;
  category_id: IProductCategory["id"];
  category_name: IProductCategory["name"];
  service_identifier: string | null;
  total: number;
}

export interface IContractProductCondition {
  action?: (h: CreateElement) => VNode | undefined;
  description: Component;
  icon: string;
  label: Component;
  summary: Component;
  type: string;
}

export enum ContractStatusCodes {
  ACTIVE = "contract_active",
  AWAITING_ACTIVATION = "contract_awaiting_activation",
  CANCELLED = "contract_cancelled",
  CLOSED = "contract_closed",
  FRAUD = "contract_fraud",
  PENDING = "contract_pending",
  SUSPENDED = "contract_suspended"
}

export interface IContractStatusMap {
  name: string;
  type: string;
  icon: string;
}

export function ContractStatusMap(): Record<
  ContractStatusCodes,
  IContractStatusMap
> {
  return {
    [ContractStatusCodes.ACTIVE]: {
      name: i18n.t("_.active") as string,
      type: "is-success",
      icon: "check-circle"
    },
    [ContractStatusCodes.AWAITING_ACTIVATION]: {
      name: i18n.t("_.awaiting_activation") as string,
      type: "is-info",
      icon: "power-off"
    },
    [ContractStatusCodes.CANCELLED]: {
      name: i18n.t("_.cancelled") as string,
      type: "is-dark",
      icon: "times-circle"
    },
    [ContractStatusCodes.CLOSED]: {
      name: i18n.t("_.lapsed") as string,
      type: "is-grey",
      icon: "ban"
    },
    [ContractStatusCodes.FRAUD]: {
      name: i18n.t("_.fraud") as string,
      type: "is-danger",
      icon: "shield-alt"
    },
    [ContractStatusCodes.PENDING]: {
      name: i18n.t("_.pending") as string,
      type: "is-warning",
      icon: "sync-alt"
    },
    [ContractStatusCodes.SUSPENDED]: {
      name: i18n.t("_.suspended") as string,
      type: "is-danger",
      icon: "pause-circle"
    }
  };
}

export interface IContractMappedStatus extends IContractStatusMap, IStatus {}

export function ContractStatusMapped(status: IStatus): IContractMappedStatus {
  return { ...status, ...ContractStatusMap()[status.code] };
}
