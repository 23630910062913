<script lang="ts">
import { defineComponent } from "vue";
import _ from "@/boot/lodash";
import FilteringSortingTableStore from "@/mixins/tableWithFilteringAndSortingStore";
import MultilevelCategoriesFetcher from "@/components/app/admin/catalogue/products/multilevelCategories/multilevelCategoriesFetchMixin";
import Context from "@/mixins/context";
import { DataModules } from "@/store/modules/data/modules";
import { QUERY_PARAMS } from "@/data/constants";
import { RegexMatch } from "@/data/enums/router";
import type { PropType, CreateElement } from "vue";
import type { IFilter, ISorter } from "@/models/tables";
import type { IClient } from "@/models/clients";
import type {
  ICProdsProviderData,
  ICProdsProviderMethods
} from "@/models/providers/contractProduct";
import type { IContractProduct } from "@/models/contracts";
import type { IAccount } from "@/models/accounts";

export default defineComponent({
  name: "CProdsProvider",
  mixins: [FilteringSortingTableStore, MultilevelCategoriesFetcher, Context],
  provide() {
    return {
      cProdsProvider: this.cProdsProvider,
      cProdsProviderMethods: this.cProdsProviderMethods
    };
  },
  props: {
    clientId: { type: String as PropType<IClient["id"]>, default: null },
    accountId: { type: String as PropType<IAccount["id"]>, default: null },
    id: { type: String, default: "CPL" },
    limit: { type: Number, default: 3 },
    handleLoading: { type: Boolean, default: true },
    handleRequestError: { type: Boolean, default: true },
    manualFilters: {
      type: Object as PropType<Record<string, string | number | boolean>>,
      default: () => ({})
    },
    availableFilters: {
      type: Function as PropType<(vm?: any) => IFilter[]>,
      default: () => []
    },
    availableSorters: {
      type: Function as PropType<(vm?: any) => ISorter[]>,
      default: () => []
    },
    isStaged: { type: Boolean, default: false }
  },
  data: () => ({
    dataModule: DataModules.CONTRACTS_PRODUCTS,
    debounceSearch: null as any,
    isLoading: true,
    hasRequestError: false,
    isProcessing: false,
    searchQuery: ""
  }),
  computed: {
    clientFilter() {
      return this.clientId ? { client_id: this.clientId } : {};
    },
    queryFilter() {
      return this.searchQuery ? { query: this.searchQuery } : {};
    },
    withStagedImports() {
      return this.isStaged ? { with_staged_imports: 1 } : {};
    },
    canShowMore(): boolean {
      return _.isNull(this.table.total)
        ? false
        : this.table.total > this.table.data.length;
    },
    mappedProducts(): ICProdsProviderData["products"] {
      return _.map(this.table.data as IContractProduct[], cProd => ({
        ...cProd,
        client_id: this.clientId || cProd.clients?.[0]?.id
      }));
    },
    hasProducts(): boolean {
      return !!this.mappedProducts.length;
    },
    cProdsProviderMethods(): ICProdsProviderMethods {
      return {
        doQuickSearch: this.doQuickSearch,
        onFiltersChange: this.onFiltersChange,
        onLimitChange: this.onLimitChange,
        onPageChange: this.onPageChange,
        onSort: this.onSort,
        reloadData: this.reloadData,
        startNewOrder: this.startNewOrder
      };
    }
  },
  async created() {
    this.debounceSearch = this.$_.debounce(this.doQuickSearch, 250);
    this.loadData();
  },
  methods: {
    async loadData() {
      try {
        // Load filters data
        if (
          _.some(this.availableFilters(), filter =>
            filter.key.includes("category.id")
          )
        ) {
          await this.getRootCategories(true);
        }

        this.setFiltersAndSorters();

        await this.getData({ ignoreStored: true });

        this.hasRequestError = false;
      } catch (error) {
        this.$store.dispatch("api/handleError", error);
        this.hasRequestError = true;
      } finally {
        this.isLoading = false;
        this.isReloading = false;
      }
    },
    setFiltersAndSorters() {
      this.filters = this.getProductCategoryFilledFilters(
        this.availableFilters(),
        false
      );
      this.sorters = this.availableSorters();
    },
    getStoreParams(params: any): Promise<any> {
      return this.$_.merge(
        {
          params: {
            with: this.$store.getters[
              `data/${DataModules.CONTRACTS_PRODUCTS}/withParam`
            ]({ clientId: this.clientId }),
            ...this.queryFilter,
            ...this.clientFilter,
            ...this.manualFilters,
            ...this.withStagedImports
          }
        },
        params // Important – must merge last
      );
    },
    async reloadData() {
      this.isReloading = true;
      await this.cancelRequests();

      return this.loadData();
    },
    doQuickSearch(value = "") {
      if (value === this.searchQuery) return Promise.resolve();
      this.searchQuery = value;
      return this.reloadData();
    },
    startNewOrder() {
      if (this.isAdmin) {
        if (this.clientId && this.accountId)
          return this.$router.push({
            name: "adminOrderShop",
            params: { basketId: RegexMatch.NEW },
            query: {
              [QUERY_PARAMS.ACCOUNT_ID]: this.accountId,
              [QUERY_PARAMS.CLIENT_ID]: this.clientId
            }
          });
        return this.$store.dispatch("data/contracts/startNewOrder");
      } else {
        return this.$router.push({ name: "order" });
      }
    },
    cProdsProvider(): ICProdsProviderData {
      return {
        activeFilters: this.activeFilters,
        activeSort: this.table.sort,
        availableFilters: this.filters,
        availableSorters: this.sorters,
        canShowMore: this.canShowMore,
        clientId: this.clientId,
        debounceSearch: this.debounceSearch,
        enableFiltering: this.enableFiltering,
        enableSorting: this.enableSorting,
        hasProducts: this.hasProducts,
        isLoading: this.table.loading || this.isLoading,
        limit: this.table.limit,
        page: this.table.page,
        products: this.mappedProducts,
        searchQuery: this.searchQuery,
        table: this.table,
        total: this.table.total
      };
    }
  },
  render(h: CreateElement) {
    // Handle loading
    if (this.handleLoading && this.loading)
      return h("is-loading", { props: { if: true } });
    // Handle request error
    if (this.handleRequestError && this.requestError)
      return h("request-error", {
        props: { isLoading: this.table.loading && this.requestError },
        on: { click: () => this.getData() }
      });
    return (
      this.$scopedSlots?.default &&
      this.$scopedSlots.default({
        $cProdsData: this.cProdsProvider(),
        $cProdsMethods: this.cProdsProviderMethods
      })
    );
  }
});
</script>
